import { useState, useEffect, useMemo, memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { Emoji } from '../Emoji'
import { IcebergLevel } from './IcebergLevel'

import {
  IcebergLevels as IcebergLevelsT,
  IcebergMeme,
  IGatsbyImagesGroup,
} from '../../@types/mememanifesto'
import { getImage } from 'gatsby-plugin-image'
import { fontSneakyTitling } from '../../theme/theme'

type IcebergData = IGatsbyImagesGroup<
  IcebergLevelsT,
  {
    publicURL: string
  }
>

type IcebergMemes = { [key in IcebergLevelsT]: IcebergMeme[] }

const Sneaky = styled.div`
  ${fontSneakyTitling}
`

const MindBlown = styled(Emoji)`
  font-size: 2em;
  vertical-align: middle;
`

interface Props {
  inViewLevel: IcebergLevelsT | undefined
  setInViewLevel: React.Dispatch<
    React.SetStateAction<IcebergLevelsT | undefined>
  >
}

export const IcebergLevels = memo<Props>(
  ({ inViewLevel, setInViewLevel }) => {
    const data = useStaticQuery<
      IcebergData & {
        allLevelsYaml: {
          nodes: {
            chapterTitle: string
          }[]
        }
      }
    >(graphql`
      query {
        allFile(
          filter: { sourceInstanceName: { eq: "memes" } }
          sort: { fields: name }
        ) {
          group(field: relativeDirectory) {
            nodes {
              name
              publicURL
              childImageSharp {
                gatsbyImageData(width: 600, placeholder: NONE)
              }
            }
            fieldValue
          }
        }
        allLevelsYaml {
          nodes {
            chapterTitle
          }
        }
      }
    `)

    const imagesObj = (): IcebergMemes => ({
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
      '9': [],
      '10': [],
    })

    const [images, setImages] = useState<IcebergMemes>()
    const [contribImages, setContribImages] = useState<IcebergMemes>()

    useEffect(() => {
      const images = imagesObj()

      data.allFile.group.forEach(({ fieldValue, nodes }) =>
        images[fieldValue].push(
          ...nodes.map(node => ({
            name: node.name,
            publicURL: node.publicURL,
            image: getImage(node),
          }))
        )
      )

      setImages(images)

      const fetchMemes = async () => {
        const contribImages = imagesObj()

        try {
          const response = await fetch(
            'https://mememanifesto-server.herokuapp.com/memes',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          const data: { data: BotMeme[] } = await response.json()

          type BotMeme = {
            id: string
            name: string
            image: string
            year: string | null
            author: string | null
            platform: string | null
            link: string | null
            category: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
          }

          Object.values(data.data).forEach(meme => {
            const level = meme.category.toString() as IcebergLevelsT
            contribImages[level].push({
              name: meme.name,
              image: false,
              publicURL: meme.image,
              year: meme.year ?? undefined,
              user: meme.author ?? undefined,
              platform: meme.platform ?? undefined,
              link: meme.link ?? undefined,
              contrib: true,
            })
          })
        } catch (error) {
          console.error(error)
        }
        setContribImages(contribImages)
      }

      fetchMemes()
    }, [data.allFile.group])

    const i1 = images?.['1'] ?? undefined
    const c1 = contribImages?.['1'] ?? undefined
    const images1 = useMemo(() => (i1 && c1 ? [...i1, ...c1] : []), [i1, c1])
    const i2 = images?.['2'] ?? undefined
    const c2 = contribImages?.['2'] ?? undefined
    const images2 = useMemo(() => (i2 && c2 ? [...i2, ...c2] : []), [i2, c2])
    const i3 = images?.['3'] ?? undefined
    const c3 = contribImages?.['3'] ?? undefined
    const images3 = useMemo(() => (i3 && c3 ? [...i3, ...c3] : []), [i3, c3])
    const i4 = images?.['4'] ?? undefined
    const c4 = contribImages?.['4'] ?? undefined
    const images4 = useMemo(() => (i4 && c4 ? [...i4, ...c4] : []), [i4, c4])
    const i5 = images?.['5'] ?? undefined
    const c5 = contribImages?.['5'] ?? undefined
    const images5 = useMemo(() => (i5 && c5 ? [...i5, ...c5] : []), [i5, c5])
    const i6 = images?.['6'] ?? undefined
    const c6 = contribImages?.['6'] ?? undefined
    const images6 = useMemo(() => (i6 && c6 ? [...i6, ...c6] : []), [i6, c6])
    const i7 = images?.['7'] ?? undefined
    const c7 = contribImages?.['7'] ?? undefined
    const images7 = useMemo(() => (i7 && c7 ? [...i7, ...c7] : []), [i7, c7])
    const i8 = images?.['8'] ?? undefined
    const c8 = contribImages?.['8'] ?? undefined
    const images8 = useMemo(() => (i8 && c8 ? [...i8, ...c8] : []), [i8, c8])
    const i9 = images?.['9'] ?? undefined
    const c9 = contribImages?.['9'] ?? undefined
    const images9 = useMemo(() => (i9 && c9 ? [...i9, ...c9] : []), [i9, c9])
    const i10 = images?.['10'] ?? undefined
    const c10 = contribImages?.['10'] ?? undefined
    const images10 = useMemo(
      () => (i10 && c10 ? [...i10, ...c10] : []),
      [i10, c10]
    )

    const levelsData = data.allLevelsYaml.nodes

    if (images === undefined || contribImages === undefined) {
      return (
        <>
          <IcebergLevel
            level="1"
            images={images1}
            visible={inViewLevel === '1'}
            title={levelsData[0].chapterTitle}
            heading={
              <>
                <Sneaky>The</Sneaky>
                <Sneaky>Internet</Sneaky>
                <div>Love</div>
                <Sneaky>Machine</Sneaky>
              </>
            }
            subheading={
              <>
                The meme is born and <br />
                Bill <em>Haz Cheezburger</em>
              </>
            }
            middleText={
              <div>
                First came <br />
                the feels
              </div>
            }
            imageWidth={[10, 35]}
            verticalMargin={[-50, 50]}
            setInViewLevel={setInViewLevel}
          />
        </>
      )
    }

    return (
      <>
        <IcebergLevel
          level="1"
          images={images1}
          visible={inViewLevel === '1'}
          title={levelsData[0].chapterTitle}
          heading={
            <>
              <Sneaky>The</Sneaky>
              <Sneaky>Internet</Sneaky>
              <div>Love</div>
              <Sneaky>Machine</Sneaky>
            </>
          }
          subheading={
            <>
              The meme is born and <br />
              Bill <em>Haz Cheezburger</em>
            </>
          }
          middleText={
            <div>
              First came <br />
              the feels
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="2"
          images={images2}
          visible={inViewLevel === '2'}
          title={levelsData[1].chapterTitle}
          heading={
            <>
              <div>Just</div>
              <Sneaky>for</Sneaky>
              <div>hits</div>
            </>
          }
          subheading={
            <>
              The meme enters the mainstream, <br />
              and eventually dies
            </>
          }
          middleText={<div>The joy, the pain, the anger.</div>}
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="3"
          images={images3}
          visible={inViewLevel === '3'}
          title={levelsData[2].chapterTitle}
          heading={
            <>
              <div>Idk</div>
              <Sneaky>but it's</Sneaky>
              <Sneaky>totally</Sneaky>
              <div>me</div>
            </>
          }
          subheading="The meme gets relatable, but how"
          middleText={
            <div>
              The feels kept us together, in the frozen depths of the www.
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="4"
          images={images4}
          visible={inViewLevel === '4'}
          title={levelsData[3].chapterTitle}
          heading={
            <>
              <Sneaky>We are</Sneaky>
              <div>
                <Sneaky as="span">at</Sneaky> war
              </div>
            </>
          }
          subheading={
            <>
              The meme is a means, <br />
              the meme is a god
            </>
          }
          middleText={<div>Then came the magic.</div>}
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="5"
          images={images5}
          visible={inViewLevel === '5'}
          title={levelsData[4].chapterTitle}
          heading={
            <>
              <div>
                <Sneaky as="span">Hey</Sneaky> Boy
              </div>
              <div>
                <Sneaky as="span">Hey</Sneaky> Girl
              </div>
            </>
          }
          subheading={
            <>
              The meme applies a strong binarization <br />
              of society. Also the meme:{' '}
              <MindBlown emoji="🤯" aria-label="mind.blown" />
            </>
          }
          middleText={<div>Monsters and shadows awaited behind the door.</div>}
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="6"
          images={images6}
          visible={inViewLevel === '6'}
          title={levelsData[5].chapterTitle}
          heading={
            <>
              <Sneaky>Diaspora</Sneaky> time!
            </>
          }
          subheading="The meme keeps the peeps together, no matter what"
          middleText={
            <div>
              We were banned, we were chased, we got lost,
              <br />
              we met again on the other side of the yard.
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="7"
          images={images7}
          visible={inViewLevel === '7'}
          title={levelsData[6].chapterTitle}
          heading={
            <>
              <Sneaky>Shit is</Sneaky>
              <Sneaky>getting</Sneaky>
              <div>meta</div>
            </>
          }
          subheading="Is a meme a meme or a meme?"
          middleText={<div>A meme is a meme is a meme is a meme.</div>}
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="8"
          images={images8}
          visible={inViewLevel === '8'}
          title={levelsData[7].chapterTitle}
          heading={
            <>
              <Sneaky>We</Sneaky>
              <Sneaky>live in</Sneaky>
              <div>
                <Sneaky as="span">a</Sneaky> society
              </div>
            </>
          }
          subheading="The meme is an inside job"
          middleText={
            <div>
              But, could you ever imagine that some pizza would have come so
              far?
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="9"
          images={images9}
          visible={inViewLevel === '9'}
          title={levelsData[8].chapterTitle}
          heading={
            <>
              <div>Hhelo</div>
              <Sneaky>isopod</Sneaky>
            </>
          }
          subheading={
            <>
              You have come so far, <br />
              why nOt visiT the ṽ̴̧͚̝̰̾õ̸̜̰i̴̧̿̀̈́͝ͅd̴̘͍͂
            </>
          }
          middleText={
            <div>
              You were like a little baby.
              <br />
              Watch this.
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
        <IcebergLevel
          level="10"
          images={images10}
          visible={inViewLevel === '10'}
          title={levelsData[9].chapterTitle}
          heading={
            <>
              <Sneaky as="span">Trigge</Sneaky>red
            </>
          }
          subheading="Text text text"
          middleText={
            <div>
              SUCC.
              <br />
              COngrAts! Your brain is fried.
              <br /> Welcome to the snafu.
            </div>
          }
          imageWidth={[10, 35]}
          verticalMargin={[-50, 50]}
          setInViewLevel={setInViewLevel}
        />
      </>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.inViewLevel === nextProps.inViewLevel
  }
)
