import styled from 'styled-components'

import { Slide } from '../Slide'
import { SlideHeading } from '../SlideHeading'
import { TitleMemeManifesto } from '../TitleMemeManifesto'

import { SlideSide } from '../../@types/mememanifesto'
import { fontSneaky } from '../../theme/theme'
import { Flex } from '../Flex'
import { StaticImage } from 'gatsby-plugin-image'

const AboutContent = styled.div<{ side: SlideSide }>`
  ${fontSneaky}
  margin-top: 1em;
  position: relative;
  max-width: 640px;
  text-align: left;
  margin-bottom: ${({ side }) => (side === 'bottom' ? 92 : 0)}px;

  p {
    margin-bottom: 1em;
  }
`

const Section = styled.div`
  margin-bottom: 2.5rem;
`

interface Props {
  side: SlideSide
}

export const About: React.FC<Props> = ({ side }) => {
  const logoStyle = { filter: side === 'bottom' ? 'invert(1)' : 'none' }
  return (
    <Slide
      side={side}
      heading={
        <SlideHeading>
          <TitleMemeManifesto />
        </SlideHeading>
      }
    >
      <AboutContent side={side}>
        <div>
          <Section>
            <p>
              We believe that memes are the latest iteration of the one true
              power that defines us as a species:
            </p>
            <p>
              <em>
                The faculty to understand, interpret and alter our reality
                through linguistic and symbolic patterns of meaning.
              </em>
            </p>
            <p>
              To understand this power means to hold the keys to control the
              uncertain grounds on which our common social world is built.
            </p>
            <p>
              Memes are a powerful new cultural technology that should be
              respected and treated as such.
            </p>
            <p>
              This website is a small contribution to the ongoing collective
              effort to gain a better understanding of what memes are, how they
              work and what they can do.
            </p>
            <p>
              May it help its visitors to navigate the memesphere and embrace
              the Era of High Weirdness.
            </p>
          </Section>
          <Section>
            <h4>LIVING IN AN UNRAVELING WORLD</h4>
            <p>
              Over the past decades, Internet Memes have been used as
              constituent elements of new, online-based subcultures. They have
              been employed as tools to establish new rituals. They inspired a
              new aesthetic.
            </p>
            <p>
              Most importantly: they have become the foundation stone of a new
              kind of language, capable of sustaining the immensely accelerated
              information metabolism of the digital age.
            </p>
            <p>
              When we started this research, back in 2017, we were moved by our
              desire to understand a historical turning point in the use of
              memetic technology: the redefinition of political space following
              the US presidential election of 2016. We firmly believed that this
              event would have been unthinkable without the existence of
              internet memes – just like historical fascism wouldn’t have been
              the same without the power of cinema or radio.
            </p>
            <p>
              As we are writing this text, a giant web of conspiracy theories
              and alternative realities is both reinforcing and accelerating the
              deconstruction of our shared sense of reality, while a global
              pandemic and a major war are forcing us to confront the fragility
              of our hypercomplex world.
            </p>
            <p>
              Geopolitical arrangements that have sustained seven decades of
              relative post-world-war stability are unraveling at vertiginous
              speed. The unfathomable reality of anthropogenic climate change is
              shattering the most pessimistic scenarios laid out by the global
              scientific consensus. Not to mention the recrudescence of social
              inequality, racism, homophobia and bigotry we are witnessing
              around the world.
            </p>
          </Section>
          <Section>
            <h4>DO WE NEED MEMES? (SPOILER: YES)</h4>

            <p>
              In a moment like this, we can’t but ask ourselves: is there really
              nothing more urgent to do than studying memes? Is this really what
              the world needs right now?
            </p>
            <p>
              Indeed, we believe that these trivial, controversial bits of
              symbolic information we call “memes” can help us rebuild the
              broken web of reality entangling us all.
            </p>
            <p>
              Let’s be frank: the pre-2016 world was not perfect – far from it.
              Trump, Brexit and all the seemingly “unthinkable” events we have
              witnessed over the past years aren’t but symptoms, signs of
              illness, befalling a social body that has been silenced for far
              too long by neoliberal ideology and the primacy of the markets.
            </p>
            <p>
              The answer to these events cannot be a return to the status quo
              ante. We can and must finally unlock the doors to a different
              future.
            </p>
            <p>
              This website is a modest attempt to do so. Taking inspiration from
              ancient occult knowledge and contemporary internet communities, we
              identified 10 levels of memetic depth and connected them to 10
              topics that define our memetic research. These levels are
              represented both in the interactive archive called{' '}
              <em>The Iceberg</em> and in a series of analog and digital panels,
              which form <em>The Detective Wall</em> installation.
            </p>
          </Section>
          <Section>
            <h4>DIVE INTO THE DEPTHS OF MEMETICS!</h4>
            <p>
              The mememanifesto.space landing page, <em>The Iceberg</em>, is
              inspired by the descending path of classic meme-templates such as
              “Iceberg Tiers Parodies” or “Galaxy Brain”. Scrolling down, the
              user is led through ever deeper levels of memetic complexity, from
              the most popular formats to the most occult.
            </p>
            <p>
              To be as inclusive as possible of the countless web communities
              that contributed to enrich the history of memes,{' '}
              <em>The Iceberg</em> is also linked to Telegram bridge bot called
              AbyBot, which allows everyone to contribute to the Iceberg
              installation and database by inserting new memes.
            </p>
            <p>
              This system should allow mememanifesto.space to exist on the web
              as a forever updating archive, accessible to and expandable by all
              interested users.
            </p>
            <p>
              As the protagonist from the famous Pepe Silvia meme (better known
              as “conspiracy theory guy”) would say: everything is connected.
            </p>
            <p>
              By surfing in <em>The Detective Wall</em> pages you will be able
              to deepen your knowledge even more, reading the discoveries made
              during our investigation, level by level, color by color.
            </p>
            <p>
              We invite you to start exploring, with one last advice: please
              keep in mind that this project is continually evolving. Over the
              next days, weeks and months, more functions will be implemented
              and new areas unlocked, so we invite you to come back regularly
              and watch out for news.
            </p>
            <p>
              Also, you can reach us at clusterduck@mememanifesto.space for any
              inquiry regarding our work.
            </p>
            <p>
              Thank you very much for your attention up to now, and have a nice
              swim!
            </p>
          </Section>
          <Section>
            <h4>ABOUT MEME MANIFESTO</h4>
            <p>
              What you see here is both a beginning and a conclusion: the
              culmination of four years of collective research by Clusterduck
              into the online memesphere, and the birth of a new chapter in this
              research cycle.
            </p>
            <p>
              This website is not Meme Manifesto, rather a part of it: Meme
              Manifesto is a transmedia project that will take various forms at
              various times. Meme Manifesto was born out of one simple desire:
              to prove that memes are more than mere “funny viral images”.
            </p>
            <p>
              The will to study and preserve internet lore and meme history has
              always been present in online communities. User generated
              platforms such as Know Your Meme, Encyclopedia Dramatica, Urban
              Dictionary, Fandom and countless other fanbase wikis are
              continuing to serve this purpose with a deep respect towards
              attributions and sources. In this sense, Clusterduck’s work is in
              its own way part of a much wider community, without which Meme
              Manifesto would have never been possible. Our thanks go to all the
              beautiful people keeping these collective projects alive.
            </p>
            <p>
              Meme studies are a young, heterogeneous and still emerging field.
              Increasingly numerous groups of students, researchers and creators
              are using different but similarly useful methods and crafts to
              cast some light on the memetic chaos surrounding us. From Discord
              servers to Telegram and Facebook groups, from hackathons and data
              analysis activities to metameme design or user-generated wikis,
              and sometimes even academic courses. We thank everyone who has
              inspired us along our path with their work and look forward to
              more weird, fruitful and stimulating discussions.
            </p>
            <p>
              During our quest,{' '}
              <a
                href="https://thephilosophersmeme.com"
                target="_blank"
                rel="noreferrer"
              >
                The Philosopher Meme
              </a>
              ,
              <a href="https://oilab.eu" target="_blank" rel="noreferrer">
                Open Intelligence Lab
              </a>{' '}
              and some of the studies and podcasts carried on by Joshua
              Citarella have been important references and starting points for
              us, inspired by similar ideals.
            </p>
          </Section>
          <Section>
            <h4>THE PROJECT’S HISTORY SO FAR</h4>
            <p>
              Meme Manifesto is a collective work that began in 2017, and later
              on developed on various platforms and supports, acquiring a
              transmedia character. The first appearance of the project happened
              with an art book, presented at the Wrong Digital Biennale with
              French artist Jules Durand. Many ideas and contents of Meme
              Manifesto go back to the project #MEMEPROPAGANDA (2018), an
              IRL/URL exhibition and a reflection on the growing role of memes,
              as well as an experiment in collective memetic production, and to
              Clusterduck’s Telegram chat Just Fresh Dank Memes, active since
              2017. The work on Meme Manifesto continued in 2020 with the first
              deployment of the mememanifesto.space website and the early
              versions of The Detective Wall, later complemented by a book, The
              Detective Wall Guide (2021).
            </p>
            <p>
              Since the perspective of a single group of users would never be
              able to replicate the complexity of the memesphere, we also
              decided to expand and explode the structure of the Detective Wall
              with a series of participatory workshops, documented through a
              format called <em>The Protocols</em>. For the time being, this
              process has already involved users and communities coming from{' '}
              <small>Brazil</small>, <em>Spain</em>, M e x i c o, the N e t h e
              r l a n d s, <em>the Czech Republic</em>, <big>Vietnam</big>,
              Italy, <small>France</small>, the UK and <small>Germany</small>.
            </p>
            <p>
              In addition to this, we also decided to revive the grassroots
              energy of the Web 1.0, engaging in a series of archiving
              activities that include this website, a Telegram Bridge Bot called
              AbyBot (a modest, affectionate homage to our inspirator Aby
              Warburg), and a continuous traffic of links, files and zip folders
              that regularly takes place between us and our extended network.
              One day, we hope, all this work will be collected in a book that
              will take the name of Meme Manifesto.
            </p>
            <p>
              Each of the abovementioned media proved useful in its own way to
              help us explore the mysterious and immense world of the
              “memesphere”, and the universe of internet platforms and
              communities that produce them.
            </p>
            <p>
              As a whole, the project wants to describe the subjective
              experience of someone who is approaching meme culture, affirm the
              importance and power of memes, and give everyone the tools to
              harness this power.
            </p>
          </Section>
          <Section>
            <h4>ATTRIBUTION NOTE</h4>
            <p>Thanks for visiting mememanifesto.space!</p>
            <p>
              Regarding the memes shown on the website: we tried to correctly
              track and credit as many of them as possible, crediting the
              authors where necessary – not always an easy task, as you might
              imagine ;)
            </p>
            <p>
              We deeply thanks our wizard developer Gregorio Magini, Deejay
              Detweiler for the sound design of The Iceberg, packed with
              references, Jules Durand for the typeface design, Umberto Boschi
              also called the Alchimista for the Aby Bot developing, and Pietro
              Ariel Parisi also called Superinterneto for adding some liquid
              magic to The Iceberg with his webGL shader.
              <br />
              Clusterduck and Meme Manifesto keep happening because of the
              constant, but often not retributed work of Tommaso Cappelletti,
              Silvia Dal Dosso, Francesca Del Bono, Arianna Magrini and Noel
              Nicolaus, often happening during the nights and in the time we
              could steal from work. For this reason please be kind and if you
              ever feel like sending help, drastically{' '}
              <a href="https://paypal.me/realclusterduck">click here</a>.
            </p>
            <p>
              If you think some of the material we shared shouldn’t be here, or
              happen to be the author of one or more of the works shown, please
              don’t hesitate to{' '}
              <a href="mailto:clusterduck@mememanifesto.space">contact us</a>.
            </p>
            <p>
              All of Clusterduck’s work is released under
              <br />
              Attribution-NonCommercial-NoDerivatives CC-license.
            </p>
            <p>This website is powered by:</p>
            <Flex
              justify="space-evenly"
              align="center"
              style={{ marginBottom: 40 }}
            >
              <a
                href="https://villa-arson.fr/"
                style={{ flex: '26%', margin: '0 20px' }}
              >
                <StaticImage
                  alt="Villa Arson"
                  src="../../assets/images/arson.png"
                  objectFit="contain"
                  imgStyle={logoStyle}
                />
              </a>
              <a
                href="https://emare.eu/"
                style={{ flex: '30%', margin: '0 20px' }}
              >
                <StaticImage
                  alt="Emare"
                  src="../../assets/images/emare.png"
                  objectFit="contain"
                  imgStyle={logoStyle}
                />
              </a>
              <a
                href="https://impakt.nl/"
                style={{ flex: '44%', margin: '0 20px' }}
              >
                <StaticImage
                  alt="IMPAKT"
                  src="../../assets/images/impakt.png"
                  objectFit="contain"
                  imgStyle={logoStyle}
                />
              </a>
            </Flex>
          </Section>
        </div>
      </AboutContent>
    </Slide>
  )
}
