import styled from 'styled-components'
import { IcebergLevels } from '../../@types/mememanifesto'

import icon1 from '../../assets/images/icon-1.png'
import icon2 from '../../assets/images/icon-2.png'
import icon3 from '../../assets/images/icon-3.png'
import icon4 from '../../assets/images/icon-4.png'
import icon5 from '../../assets/images/icon-5.png'
import icon6 from '../../assets/images/icon-6.png'
import icon7 from '../../assets/images/icon-7.png'
import icon8 from '../../assets/images/icon-8.png'
import icon9 from '../../assets/images/icon-9.png'
import icon10 from '../../assets/images/icon-10.png'

const icons = {
  '1': icon1,
  '2': icon2,
  '3': icon3,
  '4': icon4,
  '5': icon5,
  '6': icon6,
  '7': icon7,
  '8': icon8,
  '9': icon9,
  '10': icon10,
}

export const LevelIcon = styled.div<{
  size?: 'big' | 'small'
  level?: IcebergLevels
  border?: boolean
}>`
  width: ${props => (props.size === 'big' ? 82 : 50)}px;
  height: ${props => (props.size === 'big' ? 82 : 50)}px;
  line-height: 46px;
  border-radius: 100%;
  border: ${({ border, level, theme }) =>
    `2px solid ${
      border ? theme.color('topTextColor') : theme.colors[`level${level}`]
    }`};
  background: no-repeat 0 0 / 100% auto;
  background-color: ${props =>
    props.level ? props.theme.colors[`level${props.level}`] : 'transparent'};
  background-image: ${props =>
    props.level ? 'url(' + icons[props.level] + ')' : 'none'};
  text-align: center;
`
