import { useEffect, useRef, useState } from 'react'
import { PageId } from '../@types/mememanifesto'

/** Effetto autoscroll sull'iceberg */
export const useIcebergAutoscroll = (page: PageId | undefined) => {
  const [sinking, setSinking] = useState(false)
  const prevScrollHeight = useRef<number>()

  useEffect(() => {
    let interval: NodeJS.Timer
    let listener: () => void

    if (sinking) {
      interval = setInterval(() => {
        // TOrna in cima dopo che è arrivato in fondo
        if (prevScrollHeight.current === document.scrollingElement?.scrollTop) {
          prevScrollHeight.current = 0
          window.scrollTo({ top: 0 })
          clearInterval(interval)
          setSinking(false)
        } else {
          prevScrollHeight.current = document.scrollingElement?.scrollTop
          window.scrollBy({ top: 8, behavior: 'smooth' })
        }
      }, 100)
      listener = () => {
        clearInterval(interval)
        setSinking(false)
      }
      window.addEventListener('mousemove', listener, { once: true })
      window.addEventListener('mousedown', listener, { once: true })
      window.addEventListener('wheel', listener, { once: true })
      window.addEventListener('touchstart', listener, { once: true })
      window.addEventListener('keydown', listener, { once: true })
    }

    return () => {
      clearInterval(interval)
      window.removeEventListener('mousemove', listener)
      window.removeEventListener('mousedown', listener)
      window.removeEventListener('wheel', listener)
      window.removeEventListener('touchstart', listener)
      window.removeEventListener('keydown', listener)
    }
  }, [sinking])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    let listener: () => void

    if (page === 'the-deeps' && !sinking) {
      const timeoutCallback = () => {
        setSinking(true)
      }

      listener = () => {
        clearTimeout(timeout)
        timeout = setTimeout(timeoutCallback, 10000)
      }

      window.addEventListener('mousemove', listener)
      window.addEventListener('mousedown', listener)
      window.addEventListener('wheel', listener)
      window.addEventListener('touchstart', listener)
      window.addEventListener('keydown', listener)

      // Trigger on enter
      listener()
    }

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('mousemove', listener)
      window.removeEventListener('mousedown', listener)
      window.removeEventListener('wheel', listener)
      window.removeEventListener('touchstart', listener)
      window.removeEventListener('keydown', listener)
    }
  }, [page, sinking])

  return sinking
}
