import { useContext } from 'react'
import { SlideSide } from '../../@types/mememanifesto'
import { MenuMobile } from './MenuMobile'
import { MenuDesktop } from './MenuDesktop'
import { AppContext } from '../../contexts/AppContext'

interface Props {
  side: SlideSide
}

export const Menu: React.FC<Props> = props => {
  const { isTablet } = useContext(AppContext)
  return (
    <>
      {isTablet === true && <MenuMobile {...props} />}
      {isTablet === false && <MenuDesktop {...props} />}
    </>
  )
}
