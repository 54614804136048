import { useCallback, useContext, useEffect, useState } from 'react'

import { IcebergLevels } from '../@types/mememanifesto'
import { SoundContext } from '../contexts/SoundContext'

import sound1 from '../assets/sounds/levels/1.m4a'
import sound2 from '../assets/sounds/levels/2.m4a'
import sound3 from '../assets/sounds/levels/3.m4a'
import sound4 from '../assets/sounds/levels/4.m4a'
import sound5 from '../assets/sounds/levels/5.m4a'
import sound6 from '../assets/sounds/levels/6.m4a'
import sound7 from '../assets/sounds/levels/7.m4a'
import sound8 from '../assets/sounds/levels/8.m4a'
import sound9 from '../assets/sounds/levels/9.m4a'
import sound10 from '../assets/sounds/levels/10.m4a'

export const useIcebergSound = () => {
  const { fadeIn, fadeOut, fadeOutThenUnload, newSound } =
    useContext(SoundContext)

  const [sounds] = useState(() => ({
    '1': newSound(sound1),
    '2': newSound(sound2),
    '3': newSound(sound3),
    '4': newSound(sound4),
    '5': newSound(sound5),
    '6': newSound(sound6),
    '7': newSound(sound7),
    '8': newSound(sound8),
    '9': newSound(sound9),
    '10': newSound(sound10),
  }))

  useEffect(() => {
    return () => {
      Object.values(sounds).forEach(sound => fadeOutThenUnload(sound))
    }
  }, [sounds, fadeOutThenUnload])

  const setSoundLevel = useCallback(
    (newLevel: IcebergLevels) => {
      fadeIn(sounds[newLevel])
      Object.values(sounds).forEach(sound => {
        if (sound !== sounds[newLevel]) {
          fadeOut(sound)
        }
      })
    },
    [fadeIn, fadeOut, sounds]
  )

  const stopAllSounds = () => {
    Object.values(sounds).forEach(sound => {
      if (sound.playing()) {
        fadeOut(sound)
      } else {
        sound.stop()
      }
    })
  }

  return { setSoundLevel, stopAllSounds }
}
