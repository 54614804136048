import styled from 'styled-components'

import { ExternalLink } from '../ExternalLink'
import { Flex } from '../Flex'
import { Slide } from '../Slide'
import { SlideHeading } from '../SlideHeading'

import { SlideSide } from '../../@types/mememanifesto'

import botImgTop from '../../assets/images/abybot-top.png'
import botImgBottom from '../../assets/images/abybot-bottom.png'
import lines from '../../assets/images/lines.png'
import { fontHaasGrot, fontSneaky, fontSneakyTitling } from '../../theme/theme'
import { LevelIcon } from '../icons/LevelIcon'

const Chat = styled.div`
  ${fontSneakyTitling}
  line-height: 0.6;
`

const ArchiveContent = styled.div`
  position: relative;
  max-width: 640px;
`

const ArchiveText = styled.div`
  ${fontSneaky}
  text-align: left;
  margin-bottom: 6em;
`

const Group = styled(Flex)`
  margin-bottom: ${props => props.theme.gutter * 2}px;
`

const GroupInfo = styled.div`
  flex: 1;
  margin-left: ${props => props.theme.gutter}px;
`

const GroupName = styled.div`
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
`

const GroupDescription = styled.div`
  ${fontSneaky}
  font-style: italic;
  text-align: left;
`

const Line = styled.div<{ direction?: number; level: string }>`
  flex: 1;
  background: linear-gradient(
    ${({ direction = 90 }) => direction}deg,
    transparent calc(50% - 1px),
    ${({ level, theme }) => theme.colors[`level${level}`]} calc(50% - 1px),
    ${({ level, theme }) => theme.colors[`level${level}`]} calc(50% + 1px),
    transparent calc(50% + 1px)
  );
`

const ResponsiveCircle = styled(LevelIcon)`
  width: min(50px, 9vw);
  height: min(50px, 9vw);
  line-height: min(46px, 8.8vw);
  font-size: clamp(10px, 5vw, 1rem);
`

const Inner = styled.div`
  padding-bottom: 10vh;
`

const BotBox = styled.div<{ side: SlideSide }>`
  text-align: center;
  height: 270px;
  margin: 10vh 0;
  border: 2px solid
    ${props =>
      props.theme.color(
        props.side === 'top' ? 'topTextColor' : 'bottomTextColor'
      )};
  border-radius: 32px;
  position: relative;
  padding: 96px 5vw 0;
  background-color: ${props =>
    props.theme.color(
      props.side === 'top' ? 'backgroundColor' : 'bottomBackgroundColor'
    )};
  z-index: 1;
`

interface BotLogoProps {
  side: SlideSide
}

const BotLogo = styled.img.attrs<BotLogoProps>(props => ({
  src: props.side === 'top' ? botImgTop : botImgBottom,
  alt: '',
}))<BotLogoProps>`
  width: 128px;
  height: 128px;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 64px);
  top: -52px;
  display: block;
`

const BotTitle = styled.h5`
  margin-bottom: 0;
`

const BotHandle = styled.div`
  ${fontHaasGrot}
  font-size: 0.8rem;
  line-height: 2;
`

const BotInfo = styled.p`
  ${fontHaasGrot}
  font-size: 0.6rem;
  font-weight: bold;
  margin: 0.72rem 0 0;
`

const BotCAT = styled.div<{ side: SlideSide }>`
  ${fontHaasGrot}
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: bold;
  line-height: 36px;
  height: 36px;
  color: ${props =>
    props.theme.color(
      props.side === 'top' ? 'backgroundColor' : 'bottomBackgroundColor'
    )};
  background-color: ${props =>
    props.theme.color(
      props.side === 'top' ? 'topTextColor' : 'bottomTextColor'
    )};
  border-radius: 18px;
  position: absolute;
  bottom: -18px;
  left: calc(50% - 96px);

  a {
    display: block;
    width: 192px;
    text-decoration: none;
  }
`

const Lines = styled.img`
  position: absolute;
  top: 0;
  width: calc(100% - 50px);
  left: 25px;
  right: 0;
  height: calc(272px + 10vh);
`

const TenCircles = styled(Flex)`
  margin-bottom: 10vh;
`

const TextArchive = styled.div`
  font-size: clamp(72px, 21vw, 128px);
`

interface Props {
  side: SlideSide
}

export const Archive: React.FC<Props> = ({ side }) => {
  return (
    <Slide
      side={side}
      heading={
        <SlideHeading>
          <Chat>CHAT</Chat>
          <TextArchive>ARCHIVE</TextArchive>
        </SlideHeading>
      }
      style={{
        backgroundColor: side === 'bottom' ? '#000' : undefined,
      }}
    >
      <ArchiveContent>
        <Inner>
          <BotBox side={side}>
            <BotLogo side={side} />
            <BotTitle>Aby Bot</BotTitle>
            <BotHandle>@realabybot</BotHandle>
            <BotInfo>
              Use the extraordinary @realabybot to add your meme collection to
              the Meme Manifesto Iceberg!
            </BotInfo>
            <BotCAT side={side}>
              <ExternalLink href="https://t.me/realabybot">
                Feed the Bot
              </ExternalLink>
            </BotCAT>
          </BotBox>
          <Lines src={lines} />
          <TenCircles justify="space-between">
            {(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] as const).map(
              level => (
                <Flex
                  key={level}
                  column
                  justify="space-between"
                  style={{ height: 120 }}
                >
                  <ResponsiveCircle border level={level} />
                  <Line level={level === '1' ? '10' : level} />
                  <ResponsiveCircle border>{level}</ResponsiveCircle>
                </Flex>
              )
            )}
          </TenCircles>
          <p>
            Please take some time to visit <em>The Iceberg</em> to catch the
            spirit of each level and to answer correctly to AbyBot @realabybot!
          </p>
          <p>
            Clusterduck Collective will moderate the content you’ll share by
            passing it through an antiracist, antisexist and antifascist sieve,
            and grant the originality, iconicity and usability of the collection
            of Meme Manifesto through a work of curation. For this reason, from
            the moment in which you insert your meme to the moment in which the
            meme will appear on The Iceberg, some time could pass – hours or
            even days. At that given moment, Clusterduck’s members could be
            drinking, eating, sleeping or screwing. Please be patient.
          </p>
          <p>
            <em>The Iceberg</em> database is organized in 10 different levels,
            corresponding to different eras, communities and themes of the
            memesphere. It may evolve into something else in the future.
          </p>
          <p>
            In case you need to organize your thoughts, please find the index
            below.
          </p>
          <ArchiveText>
            <p>
              We are performing our research on several Telegram groups. Join
              our chats if you want to contribute to the Meme Manifesto
              ever-updating archive!
            </p>
          </ArchiveText>
          <Group align="center">
            <LevelIcon level="1" />
            <GroupInfo>
              <GroupName>1. Memes as collective rituals</GroupName>
              <GroupDescription>Rage comics, lol-cats</GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="2" />
            <GroupInfo>
              <GroupName>2. Memes and the mainstream</GroupName>
              <GroupDescription>
                Golden Age impact memes, marketing memes
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="3" />
            <GroupInfo>
              <GroupName>3. What makes memes relatable?</GroupName>
              <GroupDescription>
                Relatable memes, Twitter memes
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="4" />
            <GroupInfo>
              <GroupName>4. Memes between technic and magic</GroupName>
              <GroupDescription>
                Political memes, hyperstitional memes
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="5" />
            <GroupInfo>
              <GroupName>5. Memes and identity politics</GroupName>
              <GroupDescription>
                Chad & Virgin, queer memes, Wojak memes, NPC
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="6" />
            <GroupInfo>
              <GroupName>
                6. Memetic diasporas and platform capitalism
              </GroupName>
              <GroupDescription>
                me_IRL_community, Tumblr, Instavawe
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="7" />
            <GroupInfo>
              <GroupName>7. References and formats</GroupName>
              <GroupDescription>Meta-memes, recursive memes</GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="8" />
            <GroupInfo>
              <GroupName>8. Memetic conspiracies</GroupName>
              <GroupDescription>
                Esoteric memes, conspiracy theories
              </GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="9" />
            <GroupInfo>
              <GroupName>9. Memetic narratives</GroupName>
              <GroupDescription>Surreal memes</GroupDescription>
            </GroupInfo>
          </Group>
          <Group align="center">
            <LevelIcon level="10" />
            <GroupInfo>
              <GroupName>10. Memes and cultural appropriation</GroupName>
              <GroupDescription>Fried memes & Black Twitter</GroupDescription>
            </GroupInfo>
          </Group>
        </Inner>
      </ArchiveContent>
    </Slide>
  )
}
