import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import MenuDesktopSvg from '../../assets/images/menu-desktop.svg'
import { AppContext } from '../../contexts/AppContext'
import { IcebergContext } from '../../contexts/IcebergContext'
import { IcebergNavigationButton } from './IcebergNavigationButton'
import { MenuMobile } from '../menu/MenuMobile'
import { useOnScroll } from '../../hooks/useOnScroll'
import { useOnWheel } from '../../hooks/useOnWheel'
import { fontImpakt } from '../../theme/theme'
import { IcebergLevels } from '../../@types/mememanifesto'

const MenuDesktopWrapper = styled.div`
  position: fixed;
  width: 350px;
  right: 96px;
  display: block;
  top: 40px;
  z-index: 9999;

  * .iceberg-menu-desktop {
    cursor: pointer;
  }

  ${props => props.theme.mediaQuery(null, 'desktop')} {
    width: calc(100vw - 192px);
    height: calc(var(--vh) - 40px);
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
`

const MenuDesktopSvgS = styled(MenuDesktopSvg)`
  ${fontImpakt}
  max-height: calc(var(--vh) - 142px);
  ${props => props.theme.mediaQuery(null, 'desktop')} {
    width: min(60vw, 350px);
  }
`

interface Props {
  inViewLevel: IcebergLevels | undefined
}

// Sono gli id presenti in menu-desktop.svg
const levelIds = `#iceberg-menu-desktop-1,
#iceberg-menu-desktop-2,
#iceberg-menu-desktop-3,
#iceberg-menu-desktop-4,
#iceberg-menu-desktop-5,
#iceberg-menu-desktop-6,
#iceberg-menu-desktop-7,
#iceberg-menu-desktop-8,
#iceberg-menu-desktop-9,
#iceberg-menu-desktop-10`

export const IcebergNavigation: React.FC<Props> = ({ inViewLevel }) => {
  const { isTablet } = useContext(AppContext)
  const { menuOpen, setMenuOpen, sinking } = useContext(IcebergContext)
  const [fixMenuOpen, setFixMenuOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOnScroll(
    useCallback(() => {
      if (!fixMenuOpen && !sinking) {
        setMenuOpen(false)
      }
    }, [fixMenuOpen, setMenuOpen, sinking])
  )

  useOnWheel(
    useCallback(() => {
      setMenuOpen(false)
    }, [setMenuOpen])
  )

  useEffect(() => {
    if (menuOpen && ref.current) {
      const els = ref.current.querySelectorAll(levelIds)
      els.forEach(el => {
        el.setAttribute('opacity', '0')
      })
      const el = ref.current.querySelector(
        `#iceberg-menu-desktop-${inViewLevel}`
      )
      el?.setAttribute('opacity', '1')
    }
  }, [menuOpen, inViewLevel])

  useEffect(() => {
    let timeout1: NodeJS.Timeout
    let timeout2: NodeJS.Timeout
    let timeout3: NodeJS.Timeout
    let timeout4: NodeJS.Timeout
    const clickListener = function (this: HTMLElement) {
      const level = this.id.match(/.*-(.*)$/)
      if (level !== null) {
        setFixMenuOpen(true)
        const el = document.querySelector(`#iceberg-${level[1]}-anchor`)
        const scroll = () =>
          el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // Scrolla 4 volte per evitare che arrivi al punto sbagliato
        // (l'altezza cambia a causa del rendering dei meme in parallasse)
        scroll()
        clearTimeout(timeout1)
        clearTimeout(timeout2)
        clearTimeout(timeout3)
        clearTimeout(timeout4)
        timeout1 = setTimeout(scroll, 250)
        timeout2 = setTimeout(scroll, 500)
        timeout3 = setTimeout(scroll, 1000)
        timeout4 = setTimeout(() => {
          setFixMenuOpen(false)
        }, 2500)
      }
    }
    let els: NodeListOf<HTMLElement>
    if (ref.current !== null) {
      els = ref.current.querySelectorAll(levelIds)
      els.forEach(el => {
        el.addEventListener('click', clickListener)
      })
    }
    return () => {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
      clearTimeout(timeout3)
      clearTimeout(timeout4)
      if (els) {
        els.forEach(el => {
          el.removeEventListener('click', clickListener)
        })
      }
    }
  }, [menuOpen])

  return (
    <>
      <IcebergNavigationButton inViewLevel={inViewLevel} />
      {menuOpen && (
        <MenuDesktopWrapper ref={ref}>
          <MenuDesktopSvgS />
          {isTablet === true && <MenuMobile side="floating" />}
        </MenuDesktopWrapper>
      )}
    </>
  )
}
