import { createContext } from 'react'
import {
  IcebergLevels,
  PageId,
  SlideName,
  SlideSide,
} from '../@types/mememanifesto'

export const IcebergContext = createContext<{
  menuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  sound: {
    setSoundLevel: (level: IcebergLevels) => void
    stopAllSounds: () => void
  }
  sinking: boolean
  scrollToLevel: IcebergLevels | undefined
  setScrollToLevel: React.Dispatch<
    React.SetStateAction<IcebergLevels | undefined>
  >
  topSlide?: SlideName
  bottomSlide?: SlideName
  setSlide: (slide: SlideName, side: SlideSide) => void
  goToPage: (page: PageId, smooth?: boolean) => void
}>({
  menuOpen: false,
  setMenuOpen: () => {},
  sound: {
    setSoundLevel: () => {},
    stopAllSounds: () => {},
  },
  sinking: false,
  scrollToLevel: undefined,
  setScrollToLevel: () => {},
  topSlide: 'iceberg',
  bottomSlide: 'iceberg',
  setSlide: () => {},
  goToPage: () => {},
})
