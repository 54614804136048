import { useContext, useEffect, useRef, useState } from 'react'
import {
  Clock,
  Mesh,
  OrthographicCamera,
  PlaneBufferGeometry,
  Scene,
  ShaderMaterial,
  WebGLRenderer,
} from 'three'
import styled from 'styled-components'

import { useWindowSize } from '../hooks/useWindowSize'
import { IcebergContext } from '../contexts/IcebergContext'
import { AppContext } from '../contexts/AppContext'

const lerp = (value1: number, value2: number, amount: number) => {
  amount = amount < 0 ? 0 : amount
  amount = amount > 1 ? 1 : amount
  return value1 + (value2 - value1) * amount
}

const vertexShader = `
varying vec2 vUv;
void main() {
vUv = uv;
gl_Position = vec4( position, 1.0 );
}`

const fragmentShader = `
varying vec2 vUv;
uniform float time;
uniform vec2 iResolution;
void main() {
#define TAU 6.28318530718
#define MAX_ITER 3
vec2 p = mod(vUv*TAU, TAU)-250.0;
// vec2 p = mod(vUv*TAU*2.0, TAU)-250.0;
vec2 i = vec2(p);
float c = 1.0;
float inten = .005;
float _time = time * 0.3 ;
for (int n = 0; n < MAX_ITER; n++)
{
 float t = _time * (1.0 - (3.5 / float(n+1)));
 i = p + vec2(cos(t - i.x) + sin(t + i.y), sin(t - i.y) + cos(t + i.x));
 c += 1.0/length(vec2(p.x / (sin(i.x+t)/inten),p.y / (cos(i.y+t)/inten)));
}
c /= float(MAX_ITER);
c = 1.17-pow(c, 1.4);
vec3 colour = vec3(pow(abs(c), 8.0));
colour = clamp(colour + vec3(0.0, 0., 0.), 0.0, 1.0);
gl_FragColor = vec4(colour, 0.1);
}`

const Container = styled.div<{
  height?: number
  canvasHeight?: number
  background: boolean
}>`
  position: absolute;
  width: 100%;
  height: ${props => props.height + 'px' ?? '100%'};
  top: 0;
  bottom: -50px;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;

  background-color: ${props =>
    props.background ? 'rgba(104, 102, 102, 0.5)' : 'none'};

  canvas {
    width: 100%;
    height: ${props => props.canvasHeight ?? 0}px;
    object-fit: cover;
  }
`

export const Water: React.FC = () => {
  const canvasContainer = useRef<HTMLDivElement>(null)
  const canvasNode = useRef<HTMLCanvasElement>(null)
  const { innerHeight } = useWindowSize()
  const [height, setHeight] = useState(0)
  const { menuOpen } = useContext(IcebergContext)
  const { isTablet } = useContext(AppContext)

  useEffect(() => {
    if (canvasNode.current === null) return
    if (canvasContainer.current === null) return

    const renderer = new WebGLRenderer({
      alpha: true,
      canvas: canvasNode.current,
    })

    const clock = new Clock()
    clock.start()

    let frameId: number
    let speedTarget = 1

    const camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
    const scene = new Scene()
    const geometry = new PlaneBufferGeometry(2, 2)
    const uniforms = {
      time: { value: 1.0 },
    }
    const material = new ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
    })

    const mesh = new Mesh(geometry, material)
    scene.add(mesh)

    const animate = () => {
      if (!document.hidden) {
        let delta = clock.getDelta()
        if (speedTarget > 1) speedTarget = lerp(speedTarget, 1, delta * 4)
        uniforms['time'].value += delta * speedTarget
        renderer.render(scene, camera)
      }
      frameId = requestAnimationFrame(animate)
    }

    animate()

    const onScroll = () => {
      speedTarget = 6
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
      cancelAnimationFrame(frameId)
    }
  }, [])

  useEffect(() => {
    const onScroll = () => {
      requestAnimationFrame(() => {
        const current = canvasContainer.current
        if (current === null) return
        const parent = current.parentElement
        if (parent === null) return

        // Set container height to simulate "scrolling"
        current.style.position = 'absolute'
        const rect = current.getBoundingClientRect()
        if (rect.top < 0) current.style.position = 'fixed'
        const parentRect = parent.getBoundingClientRect()
        const bottom = parentRect.bottom > 0 ? parentRect.bottom : 0
        setHeight(bottom < innerHeight ? bottom : innerHeight)
      })
    }

    onScroll()

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [innerHeight])

  return (
    <Container
      height={height}
      canvasHeight={innerHeight}
      ref={canvasContainer}
      background={menuOpen && isTablet === true}
    >
      <canvas ref={canvasNode} />
    </Container>
  )
}
