import { useContext } from 'react'
import styled from 'styled-components'
import { IcebergLevels } from '../../@types/mememanifesto'
import { IcebergContext } from '../../contexts/IcebergContext'
import { MemeZoomContext } from '../../contexts/MemeZoomContext'
import { Button } from '../buttons/Button'
import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'
import { Cross } from '../icons/Cross'
import { LevelIcon } from '../icons/LevelIcon'

const ButtonS = styled(Button)`
  position: sticky;
  top: ${props => props.theme.gutter * 2}px;
  right: ${props => props.theme.gutter * 2}px;
  z-index: 3;
  float: right;
  font-size: 2.8rem;
  line-height: 1;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #000;
  background-color: #000;
`

const CloseButton = styled(ButtonShadowIcon)`
  position: sticky;
  top: ${props => props.theme.gutter * 2}px;
  right: ${props => props.theme.gutter * 2}px;
  float: right;
  z-index: 2;
`

interface Props {
  inViewLevel: IcebergLevels | undefined
}

export const IcebergNavigationButton: React.FC<Props> = ({ inViewLevel }) => {
  const { menuOpen, setMenuOpen } = useContext(IcebergContext)
  const { meme } = useContext(MemeZoomContext)
  return (
    <>
      {menuOpen && (
        <CloseButton
          onClick={() => setMenuOpen(false)}
          aria-label="Close Iceberg navigation"
        >
          <Cross />
        </CloseButton>
      )}
      {!menuOpen && (
        <ButtonS
          onClick={() => meme === null && setMenuOpen(true)}
          aria-label="Open Iceberg navigation"
        >
          {inViewLevel && <LevelIcon level={inViewLevel} size="big" />}
        </ButtonS>
      )}
    </>
  )
}
