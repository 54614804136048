import { useCallback, useEffect, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { PageProps } from 'gatsby'
import styled from 'styled-components'

import { Page } from '../components/Page'
import { Iceberg } from '../components/iceberg/Iceberg'
import { Menu } from '../components/menu/Menu'
import { About } from '../components/slides/About'
import { Archive } from '../components/slides/Archive'
import { IcebergTop } from '../components/slides/IcebergTop'
import { IcebergBottom } from '../components/slides/IcebergBottom'
import { IcebergContext } from '../contexts/IcebergContext'
import { IcebergLevels, PageId, SlideName } from '../@types/mememanifesto'
import { usePrevious } from '../hooks/usePrevious'
import { useIcebergAutoscroll } from '../hooks/useIcebergAutoscroll'
import { useIcebergSound } from '../hooks/useIcebergSound'

const Wrapper = styled.div<{ slide: SlideName }>`
  width: 100%;
  overflow-y: ${props => (props.slide === 'iceberg' ? 'visible' : 'hidden')};
  height: ${props => (props.slide === 'iceberg' ? 'auto' : 'var(--vh)')};
`

const TheDeeps = styled.section<{ isBottomSlideActive: boolean }>`
  display: ${props => (props.isBottomSlideActive ? 'block' : 'none')};
  padding-bottom: 200px;
  background-color: ${props => props.theme.color('bottomBackgroundColor')};
`

const Overflow = styled.div<{
  slide: SlideName
  display?: string
}>`
  overflow: hidden;
  width: 100%;
  height: var(--vh);
  display: ${props => props.display ?? 'block'};
`

const SlideWrapper = styled.div<{ slide: SlideName }>`
  display: flex;
  width: 300%;
  transition: transform 400ms;
  transform: translateX(
    -${({ slide }) =>
        ({
          about: 0,
          iceberg: 1,
          archive: 2,
        }[slide] *
        (100 / 3))}%
  );
`

const BottomSpacer = styled.div`
  height: 400px;
  background-color: ${props => props.theme.color('bottomBackgroundColor')};
`

const PageHome: React.FC<
  PageProps<undefined, undefined, { level?: IcebergLevels }>
> = ({ location }) => {
  const [hash, setHash] = useState<string>()
  const [page, setPage] = useState<PageId>()
  const prevPage = usePrevious(page)
  const [slide, setSlide] = useState<SlideName>()
  const [topSlide, setTopSlide] = useState<SlideName>()
  const [bottomSlide, setBottomSlide] = useState<SlideName>()
  const prevSlide = usePrevious(slide)
  const [menuOpen, setMenuOpen] = useState(false)
  const sound = useIcebergSound()
  const sinking = useIcebergAutoscroll(page)
  // Prendi lo stato passato dalla pagina level per passare a IcebergLevel
  // che lo usa per scrollare automaticamente al livello iniziale
  const [scrollToLevel, setScrollToLevel] = useState<IcebergLevels | undefined>(
    location.state?.level
  )

  const icebergTopRef = useRef<HTMLElement>(null)
  const theDeepsRef = useRef<HTMLElement>(null)
  const icebergBottomRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (hash === undefined) {
      setHash(location.hash.replace('#!', ''))
    }
  }, [hash, location.hash])

  useEffect(() => {
    switch (hash) {
      case 'iceberg-top':
      case 'about-top':
      case 'archive-top':
        setPage('iceberg-top')
        break
      case 'the-deeps':
      case 'level-1':
      case 'level-2':
      case 'level-3':
      case 'level-4':
      case 'level-5':
      case 'level-6':
      case 'level-7':
      case 'level-8':
      case 'level-9':
      case 'level-10':
        setPage('the-deeps')
        break
      case 'iceberg-bottom':
      case 'about-bottom':
      case 'archive-bottom':
        setPage('iceberg-bottom')
        break
      default:
        setPage('iceberg-top')
    }

    if (hash !== undefined) {
      if (['about-top', 'about-bottom'].includes(hash)) {
        setSlide('about')
      } else if (['archive-top', 'archive-bottom'].includes(hash)) {
        setSlide('archive')
      } else {
        setSlide('iceberg')
      }
    }

    if (hash === 'about-top') {
      setTopSlide('about')
    } else if (hash === 'archive-top') {
      setTopSlide('archive')
    } else {
      setTopSlide('iceberg')
    }

    if (hash === 'about-bottom') {
      setBottomSlide('about')
    } else if (hash === 'archive-bottom') {
      setBottomSlide('archive')
    } else {
      setBottomSlide('iceberg')
    }
  }, [hash])

  // Scrolla al livello giusto all'inizo
  useEffect(() => {
    if (
      // Se sono tutti uguali significa che siamo al primo render vero
      page === 'iceberg-bottom' &&
      prevPage === 'iceberg-bottom'
    ) {
      icebergBottomRef.current?.scrollIntoView()
    }
  }, [page, prevPage, slide])

  // Callback per scrollare alla pagina giusta
  const goToPage = useCallback((pageId: PageId, smooth: boolean = true) => {
    if (smooth) {
      const scroll = (el: React.RefObject<HTMLElement>) =>
        el.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      if (pageId === 'iceberg-top') scroll(icebergTopRef)
      if (pageId === 'the-deeps') scroll(theDeepsRef)
      if (pageId === 'iceberg-bottom') scroll(icebergBottomRef)
    }
    setPage(pageId)
  }, [])

  // Gestione hash di navigazione
  useEffect(() => {
    if (
      hash !== undefined &&
      prevPage !== undefined &&
      (page !== prevPage || slide !== prevSlide)
    ) {
      // Update hashbang
      let newHash
      if (page === 'iceberg-top') newHash = `${slide}-top`
      else if (page === 'iceberg-bottom') newHash = `${slide}-bottom`
      else newHash = page
      window.location.replace(
        `${window.location.href.replace(window.location.hash, '')}#!${newHash}`
      )
    }
  }, [hash, page, prevPage, slide, prevSlide])

  const swipeTop = useSwipeable({
    onSwipedLeft: () => {
      if (slide === 'about') {
        setTopSlide('iceberg')
        setSlide('iceberg')
      } else if (slide === 'iceberg') {
        setTopSlide('archive')
        setSlide('archive')
      }
    },
    onSwipedRight: () => {
      if (slide === 'archive') {
        setTopSlide('iceberg')
        setSlide('iceberg')
      } else if (slide === 'iceberg') {
        setTopSlide('about')
        setSlide('about')
      }
    },
  })

  const swipeBottom = useSwipeable({
    onSwipedLeft: () => {
      if (slide === 'about') {
        setBottomSlide('iceberg')
        setSlide('iceberg')
      } else if (slide === 'iceberg') {
        setBottomSlide('archive')
        setSlide('archive')
      }
    },
    onSwipedRight: () => {
      if (slide === 'archive') {
        setBottomSlide('iceberg')
        setSlide('iceberg')
      } else if (slide === 'iceberg') {
        setBottomSlide('about')
        setSlide('about')
      }
    },
  })

  return (
    <Page pathname={location.pathname}>
      <IcebergContext.Provider
        value={{
          sound,
          menuOpen,
          setMenuOpen,
          sinking,
          scrollToLevel,
          setScrollToLevel,
          topSlide,
          bottomSlide,
          setSlide: (slide, side) => {
            if (side === 'top') setTopSlide(slide)
            else setBottomSlide(slide)
            setSlide(slide)
          },
          goToPage,
        }}
      >
        {slide && (
          <Wrapper slide={slide}>
            <Overflow
              slide={slide}
              display={bottomSlide === 'iceberg' ? 'block' : 'none'}
            >
              <section id="iceberg-top" ref={icebergTopRef}>
                <Menu side="top" />
                <SlideWrapper slide={slide} {...swipeTop}>
                  <About side="top" />
                  <IcebergTop />
                  <Archive side="top" />
                </SlideWrapper>
              </section>
            </Overflow>
            <TheDeeps
              id="the-deeps"
              ref={theDeepsRef}
              isBottomSlideActive={bottomSlide === 'iceberg'}
            >
              <Iceberg />
            </TheDeeps>
            {slide === 'iceberg' && <BottomSpacer />}
            <Overflow slide={slide}>
              <section
                id="iceberg-bottom"
                ref={icebergBottomRef}
                style={{ position: 'relative' }}
              >
                <SlideWrapper slide={slide} {...swipeBottom}>
                  <About side="bottom" />
                  <IcebergBottom />
                  <Archive side="bottom" />
                </SlideWrapper>
                <Menu side="bottom" />
              </section>
            </Overflow>
          </Wrapper>
        )}
      </IcebergContext.Provider>
    </Page>
  )
}

export default PageHome
