import React, { useContext } from 'react'

import { Button } from '../buttons/Button'
import { Speaker } from '../icons/Speaker'
import { SpeakerOff } from '../icons/SpeakerOff'
import { SoundContext } from '../../contexts/SoundContext'

interface Props {
  className?: string
  style?: React.CSSProperties
}

export const MenuSoundButton: React.FC<Props> = ({ className, style }) => {
  const { soundEnabled, setSoundEnabled } = useContext(SoundContext)

  return (
    <Button
      className={className}
      title={soundEnabled ? 'Turn off sound' : 'Turn on sound'}
      onClick={() => {
        setSoundEnabled(!soundEnabled)
      }}
    >
      {soundEnabled ? <Speaker style={style} /> : <SpeakerOff style={style} />}
    </Button>
  )
}
