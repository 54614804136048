interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  emoji: string
  'aria-label': string
}

export const Emoji: React.FC<Props> = ({
  emoji,
  'aria-label': ariaLabel,
  ...props
}) => (
  <span role="img" aria-label={ariaLabel} {...props}>
    {emoji}
  </span>
)
