import { useContext } from 'react'
import styled from 'styled-components'

import { Button } from '../buttons/Button'
import { IcebergContext } from '../../contexts/IcebergContext'
import { SlideName, SlideSide } from '../../@types/mememanifesto'

export const StyledMenuLink = styled.div`
  display: flex;
  margin-bottom: 0;

  & svg {
    display: block;
  }
`

interface Props {
  slide: SlideName
  side: SlideSide | 'floating'
  children: React.ReactNode
}

export const MenuMobileLink: React.FC<Props> = ({ slide, side, children }) => {
  const { setSlide } = useContext(IcebergContext)
  return (
    <StyledMenuLink>
      <Button
        onClick={() => {
          setSlide(slide, side === 'floating' ? 'top' : side)
        }}
      >
        {children}
      </Button>
    </StyledMenuLink>
  )
}
