import styled, { css } from 'styled-components'
import { IcebergLevels } from '../@types/mememanifesto'

export const backgroundGradientCSS = css<{ level: string }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    ${({ theme, level }) => theme.colors[`level${level}`]},
    ${({ theme, level }) => {
      if (level === '10') {
        return theme.colors.level10
      }
      return theme.colors[`level${(parseInt(level) + 1).toString()}`]
    }}
  );
`

export const BackgroundGradient = styled.div<{
  level: IcebergLevels
}>`
  pointer-events: none;
  z-index: -1;
  ${backgroundGradientCSS}
`
