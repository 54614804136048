import { useContext, useState } from 'react'
import { useInView } from 'react-cool-inview'
import styled from 'styled-components'
import { IcebergNavigation } from './IcebergNavigation'
import { MemeZoom } from '../MemeZoom'
import { Water } from '../Water'

import { MemeZoomContext } from '../../contexts/MemeZoomContext'
import {
  IcebergLevels as IcebergLevelsT,
  IcebergMeme,
} from '../../@types/mememanifesto'
import { IcebergLevels } from './IcebergLevels'
import { IcebergContext } from '../../contexts/IcebergContext'
import { AppContext } from '../../contexts/AppContext'

const UnderWaterS = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.gutter * 2}px;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.white},
    #2fc7ff 1%,
    #2fc7ff 30%,
    #0c2eff 45%,
    #0029b4 60%,
    #000308
  );
`

export const IcebergLevelsWrapper = () => {
  const [inViewLevel, setInViewLevel] = useState<IcebergLevelsT>()
  const [meme, setMeme] = useState<null | IcebergMeme>(null)
  const { isTablet } = useContext(AppContext)
  const { menuOpen, setMenuOpen, goToPage, sound } = useContext(IcebergContext)

  // Rispondi al cambio di livello durante lo scroll
  const { observe } = useInView<HTMLElement>({
    onEnter: () => {
      goToPage('the-deeps', false)
    },
    onLeave: event => {
      if (event.scrollDirection.vertical === 'down') {
        goToPage('iceberg-top', false)
      } else if (event.scrollDirection.vertical === 'up') {
        goToPage('iceberg-bottom', false)
      }
      setMenuOpen(false)
      sound.stopAllSounds()
    },
  })

  return (
    <UnderWaterS>
      <MemeZoomContext.Provider value={{ meme, setMeme }}>
        <MemeZoom />
        <Water />
        <IcebergNavigation inViewLevel={inViewLevel} />
        <div
          ref={observe}
          style={isTablet === true && menuOpen ? { filter: 'blur(10px)' } : {}}
        >
          <IcebergLevels
            inViewLevel={inViewLevel}
            setInViewLevel={setInViewLevel}
          />
        </div>
      </MemeZoomContext.Provider>
    </UnderWaterS>
  )
}
