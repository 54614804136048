import styled from 'styled-components'

import { Flex } from '../Flex'
import { Slide } from '../Slide'
import { fontSneaky } from '../../theme/theme'

const SlideS = styled(Slide)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const FlexS = styled(Flex)`
  ${fontSneaky}
  flex: 1;
  font-style: italic;
  font-size: 0.8rem;
`

export const IcebergBottom: React.FC = () => {
  return (
    <SlideS side="bottom">
      <FlexS column justify="center">
        Your journey has come to an end. <br />
        Or maybe it has just begun...
      </FlexS>
    </SlideS>
  )
}
