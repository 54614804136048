import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled, { keyframes } from 'styled-components'
import { IcebergLevels } from '../../@types/mememanifesto'
import { fontSneaky } from '../../theme/theme'
import { BackgroundGradient } from '../BackgroundGradient'
import { ButtonShadow } from '../buttons/ButtonShadow'
import randomInt from '../../utils/randomInt'

const Wrapper = styled.div<{
  level: IcebergLevels
  showHoverInfo: boolean
}>`
  width: 100%;
  height: calc(var(--vh) * 1.6);
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${props =>
    props.showHoverInfo && props.level !== '10' ? 'color: #000;' : ''}
`

const TextWrapper = styled.div<{
  showHoverInfo: boolean
}>`
  mix-blend-mode: ${props => (props.showHoverInfo ? 'normal' : 'difference')};
  pointer-events: none;
  flex: 1;
  position: relative;
  height: clamp(520px, 50vh, 800px);

  ${props => props.theme.mediaQuery('desktop')} {
    max-width: 50vw;
  }
`

const TextInner = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Text = styled.div`
  ${fontSneaky}
  font-size: clamp(3rem, 10vw, 5rem);
  margin-bottom: 100px;
  line-height: 1;
`

const ButtonDiscoverMore = styled(ButtonShadow)`
  pointer-events: all;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 32px;
`

const Title = styled.p<{ visible: boolean }>`
  ${fontSneaky}
  font-style: italic;
  margin: 0;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

const swimming = keyframes`
	0%, 10% {
		transform: translateX(-100vw) scaleX(1);
	}

  44.999% {
    transform: translateX(100vw) scaleX(1);
  }

  45%, 55% {
    transform: translateX(100vw) scaleX(-1);
  }

  90%, 100% {
		transform: translateX(-100vw) scaleX(-1);
	}
`

const FishWrapper = styled.div<{ duration: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  animation: ${swimming} ${props => props.duration}s linear infinite;
`

const Inner = styled.div<{ translateY: number }>`
  flex: 1;
  position: relative;
  transform: translateY(${props => props.translateY}px);
`

const ImgWrapper = styled.div`
  margin: 0 auto;
`

interface Props {
  level: IcebergLevels
  title: string
  middleText: React.ReactNode
}

export const IcebergLevelFish: React.FC<Props> = ({
  level,
  title,
  middleText,
}) => {
  const [showHoverInfo, setShowHoverInfo] = useState(false)
  const [translateY, setTranslateY] = useState(0)
  const [translateYb, setTranslateYb] = useState(0)
  const [duration] = useState(randomInt(16, 24))
  const [durationB] = useState(randomInt(25, 30))

  const ref = useRef<HTMLDivElement>(null)
  const refB = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = ref.current
    const elB = refB.current
    const listener = () => {
      setTranslateY(randomInt(-200, 200))
    }
    const listenerB = () => {
      setTranslateYb(randomInt(-200, 200))
    }
    listener()
    el?.addEventListener('animationiteration', listener)
    elB?.addEventListener('animationiteration', listenerB)
    return () => {
      el?.removeEventListener('animationiteration', listener)
      elB?.removeEventListener('animationiteration', listenerB)
    }
  }, [])

  const Wraps = (fish: React.ReactNode) => {
    return (
      <FishWrapper ref={ref} duration={duration}>
        <Inner
          key={level}
          onTouchStart={() => setShowHoverInfo(true)}
          onTouchEnd={() => setShowHoverInfo(false)}
          translateY={translateY}
        >
          <ImgWrapper>{fish}</ImgWrapper>
        </Inner>
      </FishWrapper>
    )
  }

  const imgWrapperStyle = { overflow: 'visible' }

  return (
    <>
      <Wrapper level={level} showHoverInfo={showHoverInfo}>
        {level === '1' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-01.png"
              alt="Trollfish"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '60vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '2' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-02.png"
              alt="Haroldfish"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '40vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '3' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-03.png"
              alt="Dogefish"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '60vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '4' && (
          <>
            <FishWrapper ref={ref} duration={duration}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateY}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-04a.png"
                    alt="Pepefish"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '50vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
            <FishWrapper ref={refB} duration={durationB}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateYb}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-04b.png"
                    alt="Pepino"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '10vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
          </>
        )}
        {level === '5' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-05.png"
              alt="Chadfish"
              title="Chadfish"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '50vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '6' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-06.png"
              alt="Dat Fish"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '50vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '7' && (
          <>
            <FishWrapper ref={ref} duration={duration}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateY}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-07a.png"
                    alt="Thinkfish"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '70vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
            <FishWrapper ref={refB} duration={durationB}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateYb}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-07b.png"
                    alt="Clipper"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '60vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
          </>
        )}
        {level === '8' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-08.png"
              alt="Esoteric Dolphin"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ maxHeight: '60vh' }}
              style={imgWrapperStyle}
            />
          )}
        {level === '9' && (
          <>
            <FishWrapper ref={ref} duration={duration}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateY}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-09a.png"
                    alt="Meme Squidman"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '60vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
            <FishWrapper ref={refB} duration={durationB}>
              <Inner
                key={level}
                onTouchStart={() => setShowHoverInfo(true)}
                onTouchEnd={() => setShowHoverInfo(false)}
                translateY={translateYb}
              >
                <ImgWrapper>
                  <StaticImage
                    src="../../assets/images/fish-09b.png"
                    alt="The Orangfish Species"
                    placeholder="none"
                    objectFit="contain"
                    imgStyle={{ maxHeight: '60vh' }}
                    style={imgWrapperStyle}
                  />
                </ImgWrapper>
              </Inner>
            </FishWrapper>
          </>
        )}
        {level === '10' &&
          Wraps(
            <StaticImage
              src="../../assets/images/fish-10.png"
              alt="Haha"
              placeholder="none"
              objectFit="contain"
              imgStyle={{ height: '80vh' }}
              style={imgWrapperStyle}
            />
          )}
        <TextWrapper showHoverInfo={showHoverInfo}>
          <TextInner>
            <Text>{middleText}</Text>
            <Link to={`/level-${level}`}>
              <ButtonDiscoverMore
                onMouseEnter={() => setShowHoverInfo(true)}
                onMouseLeave={() => setShowHoverInfo(false)}
              >
                Discover more about LVL. {level}
              </ButtonDiscoverMore>
            </Link>
            <Title visible={showHoverInfo}>Read about {title}</Title>
          </TextInner>
        </TextWrapper>
      </Wrapper>
      {showHoverInfo && <BackgroundGradient level={level} />}
    </>
  )
}
