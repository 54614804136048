import { useEffect } from 'react'

/** Always call with useCallback: useOnWheel(useCallback(callback, [])) */
export const useOnWheel = (
  onWheel: EventListenerOrEventListenerObject,
  el: GlobalEventHandlers = window
) => {
  useEffect(() => {
    el.addEventListener('wheel', onWheel)
    return () => el.removeEventListener('wheel', onWheel)
  }, [onWheel, el])
}
