import { useContext } from 'react'
import styled from 'styled-components'

import { Button } from '../buttons/Button'
import { IcebergContext } from '../../contexts/IcebergContext'
import { SlideName, SlideSide } from '../../@types/mememanifesto'
import { fontHaasGrot } from '../../theme/theme'

export const StyledMenuLink = styled.li<{
  active?: boolean
}>`
  ${fontHaasGrot}
  font-size: 0.8rem;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  margin-bottom: 0;
  white-space: nowrap;
`

interface Props {
  slide: SlideName
  side: SlideSide
  children: React.ReactNode
}

export const MenuDesktopLink: React.FC<Props> = ({ slide, side, children }) => {
  const { setSlide, topSlide, bottomSlide } = useContext(IcebergContext)
  return (
    <StyledMenuLink
      active={
        (side === 'top' && topSlide === slide) ||
        (side === 'bottom' && bottomSlide === slide)
      }
    >
      <Button
        onClick={() => {
          setSlide(slide, side)
        }}
      >
        {children}
      </Button>
    </StyledMenuLink>
  )
}
