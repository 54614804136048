import { Link } from 'gatsby'
import styled from 'styled-components'

import { MenuDesktopLink, StyledMenuLink } from './MenuDesktopLink'
import { MenuSoundButton } from './MenuSoundButton'
import { SlideSide } from '../../@types/mememanifesto'
import { SlidePadding } from '../Slide'

const MenuSoundButtonS = styled(MenuSoundButton)`
  position: absolute;
  right: calc(${SlidePadding} + 48px);
  top: ${({ theme }) => theme.menuHeight / 2 - 13}px;
`

const Nav = styled.nav<{ side: SlideSide }>`
  height: ${({ theme }) => theme.menuHeight}px;
  color: ${({ theme, side }) =>
    theme.color(side === 'top' ? 'topTextColor' : 'bottomTextColor')};
  position: relative;
  z-index: 1;

  ${({ side }) => {
    if (side === 'bottom') {
      return `
        position: absolute;
        bottom: 0;
        width: 100vw;
        background-image: linear-gradient(to top, #000000d6, #000000a3 67%, #0000);`
    }
  }}

  ${MenuSoundButtonS} {
    ${({ theme, side }) =>
      `
        fill: ${theme.color(
          side === 'top' ? 'topTextColor' : 'bottomTextColor'
        )};
        .stroke {
          stroke: ${theme.color(
            side === 'top' ? 'topTextColor' : 'bottomTextColor'
          )}; !important;
        }`}
  }
`

const Ul = styled.ul`
  width: 50vw;
  max-width: 600px;
  min-width: 380px;
  margin: auto;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;

  flex-direction: row;
  line-height: 1;

  > * {
    margin: 0 1vw;
  }
`

const Wrapper = styled.div<{ side: SlideSide }>`
  position: relative;
  height: 100%;
  background: ${({ side, theme }) =>
    side === 'top'
      ? theme.color('backgroundColor')
      : theme.color('bottomBackgroundColor')};
`

interface Props {
  side: SlideSide
}

export const MenuDesktop: React.FC<Props> = ({ side }) => {
  const speakerStyle = side === 'bottom' ? { filter: 'invert(1)' } : undefined
  return (
    <Nav side={side}>
      <Wrapper side={side}>
        <Ul>
          <MenuDesktopLink slide="about" side={side}>
            A Manifesto
          </MenuDesktopLink>
          <MenuDesktopLink slide="iceberg" side={side}>
            The Iceberg
          </MenuDesktopLink>
          <MenuDesktopLink slide="archive" side={side}>
            Chat archive
          </MenuDesktopLink>
          <StyledMenuLink>
            <Link to="/detective-wall" style={{ textDecoration: 'none' }}>
              Detective Wall
            </Link>
          </StyledMenuLink>
        </Ul>
        <MenuSoundButtonS style={speakerStyle} />
      </Wrapper>
    </Nav>
  )
}
