import { useContext } from 'react'
import styled from 'styled-components'
import { IcebergContext } from '../../contexts/IcebergContext'
import { fontSneaky } from '../../theme/theme'

import { ButtonShadow } from '../buttons/ButtonShadow'
import { Slide } from '../Slide'
import { SlideHeading } from '../SlideHeading'
import { TitleTheIceberg } from '../TitleTheIceberg'

const SafariHeading = styled.h3`
  ${fontSneaky}
  font-size: 24px;
  font-weight: normal;
  line-height: 2;
  margin-bottom: 0;

  ${props => props.theme.mediaQuery(null, 'tablet')} {
    font-size: 20px;
  }

  ${props => props.theme.mediaQuery(null, 'phone')} {
    font-size: 16px;
  }
`

const ButtonStart = styled(ButtonShadow)`
  height: 38px;
  font-weight: bold;
  font-size: 15px;
  padding: 0 15px;
`

export const IcebergTop: React.FC = () => {
  const { goToPage } = useContext(IcebergContext)
  return (
    <Slide
      side="top"
      heading={
        <SlideHeading>
          <TitleTheIceberg />
          <SafariHeading>
            A voyage through the feels and the deepness.
          </SafariHeading>
        </SlideHeading>
      }
      subtitles={
        <ButtonStart onClick={() => goToPage('the-deeps')}>
          Begin the journey
        </ButtonStart>
      }
    />
  )
}
