import { IcebergMeme } from '../@types/mememanifesto'

const memes: { [key: string]: IcebergMeme } = {
  '01_limitations': {
    name: 'Limitations',
    year: 1998,
    user: 'Despair.inc',
    platform: 'Something Awful/4chan/Reddit/various',
    link: 'https://despair.com/collections/posters',
  },
  '02_penguins': {
    name: 'Penguins',
    year: '2006-2008',
    user: 'Anonymous',
    platform: '4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/demotivational-posters',
  },
  '03_friends': {
    name: 'Friends',
    year: '2006-2008',
    user: 'Anonymous',
    platform: '4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/demotivational-posters',
  },
  '04_mediocrity': {
    name: 'Mediocrity',
    year: 1998,
    user: 'Despair.inc',
    platform: 'Something Awful/4chan/Reddit/various',
    link: 'https://despair.com/collections/posters',
  },
  '05_thebrightside': {
    name: 'The Bright Side',
    year: '2006-2008',
    user: 'Anonymous',
    platform: '4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/demotivational-posters',
  },
  '06_procrastination': {
    name: 'Procrastination',
    year: 1998,
    user: 'Despair.inc',
    platform: 'Something Awful/4chan/Reddit/various',
    link: 'https://despair.com/collections/posters',
  },
  '07_consistency': {
    name: 'Constistency',
    year: '2006-2010',
    user: 'Anonymous',
    platform: '4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/demotivational-posters',
  },
  '08_epicfail': {
    name: 'Epic Fail',
    year: '2010-2011',
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fail-epic-fail',
  },
  '09_burnout': {
    name: 'Burnout',
    year: 1998,
    user: 'Despair.inc',
    platform: 'Something Awful/4chan/Reddit/various',
    link: 'https://despair.com/collections/posters',
  },
  '10_irony': {
    name: 'Irony',
    year: '2010-2011',
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/demotivational-posters',
  },
  '11_memegasm': {
    name: 'Memegasm (Shoop Da Whoop + Longcat)',
    year: 2007,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://www.urbandictionary.com/define.php?term=Memegasm; https://knowyourmeme.com/memes/shoop-da-whoop; https://knowyourmeme.com/memes/longcat',
  },
  '12_unexplainable': {
    name: 'The guy holding a sewing machine, in front of a UPS Truck Accident (The unexplainable picture)',
    year: 2004,
    user: 'Anonymous',
    platform: 'flickr/\n4chan/myspace/various',
    link: 'https://knowyourmeme.com/memes/the-guy-holding-a-sewing-machine-in-front-of-a-ups-truck-accident-the-unexplainable-picture',
  },
  '13_thelongeryoulook': {
    name: 'The Longer You Look - Cursed',
    year: 'Unknown',
    user: 'Anonymous',
    platform: '',
    link: '',
  },
  '14_thelongeryoulookcat': {
    name: 'Half Cat / Panorama Fail Cat',
    year: 2010,
    user: 'Anonymous',
    platform: 'Reddit/Imgur',
    link: 'https://knowyourmeme.com/memes/half-cat-panorama-fail-cat',
  },
  '15_thelongeryoulookcafe': {
    name: 'The Longer You Look',
    year: '',
    user: 'Anonymous',
    platform: '',
    link: 'https://www.reddit.com/r/interestingasfuck/comments/8ta5nw/the_longer_you_look_at_it_the_weirder_it_gets/',
  },
  '16_internethatemachine': {
    name: 'Internet Hate Machine',
    year: 2007,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/internet-hate-machine',
  },
  "17_i'msorry": {
    name: "I'm sorry, I can't hear you over the sound of how awesome I am",
    year: '2005-2007',
    user: 'Anonymous',
    platform: '4chan/Reddit/Facebook/WOW/various',
    link: 'https://knowyourmeme.com/memes/im-sorry-i-cant-hear-you-over-the-sound-of-how-awesome-i-am',
  },
  '18_fry': {
    name: 'I see what you did there + Futurama Fry / Not Sure If',
    year: 2009,
    user: 'Anonymous',
    platform: '4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/i-see-what-you-did-there',
  },
  '19_goatse': {
    name: 'Goatse + Patrick Star cringing',
    year: 2013,
    user: 'Anonymous',
    platform: 'Reddit/various',
    link: 'https://knowyourmeme.com/memes/goatse',
  },
  '20_invading-b-': {
    name: 'Invading /B/ - how 2 4chan really good',
    year: 2015,
    user: 'Anonymous',
    platform: 'Tumblr/4chan',
    link: 'https://knowyourmeme.com/memes/events/2014-tumblr-4chan-raids',
  },
  '21_trollface': {
    name: 'Trollface',
    year: 2008,
    user: 'Carlos Ramirez / Tyler_mon / Whynne',
    platform: 'DeviantArt',
    link: 'https://knowyourmeme.com/memes/trollface',
  },
  '22_happyomegatrollface': {
    name: 'Happy Omega Trollface',
    year: '2008 (?)',
    user: 'Anonymous (?)',
    platform: '4chan (?)',
    link: 'https://meme.fandom.com/wiki/Omega_Trollface',
  },
  '23_trollseal': {
    name: 'Trolls trolling trolls seal',
    year: '2010 (?)',
    user: 'Teakay',
    platform: '4chan (?)',
    link: 'https://en.wikipedia.org/wiki/Internet_troll',
  },
  '24_megustatrollface': {
    name: 'Me Gusta + Trollface',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://meme.fandom.com/wiki/Trollface',
  },
  '25_newtrollface': {
    name: 'New Trollface 2.0',
    year: '2010 (?)',
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://meme.fandom.com/wiki/Trollface',
  },
  '26_angryderp': {
    name: 'Angry derp',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/subcultures/rage-comics',
  },
  '27_happyderp': {
    name: 'Happy derp',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/subcultures/rage-comics',
  },
  '28_ragederp': {
    name: 'Rage guy (FFFFFUUUUUUUU-)',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/rage-guy-fffffuuuuuuuu',
  },
  '29_happyderpinaeyesclosed': {
    name: 'Derpina (eyes closed)',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan/Tumblr',
    link: 'https://knowyourmeme.com/memes/derpina',
  },
  '30_happyderpinaeyesopen': {
    name: 'Derpina (eyes open)',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan/Tumblr',
    link: 'https://knowyourmeme.com/memes/derpina',
  },
  '31_cerealguysquint': {
    name: 'Cereal guy (squinting)',
    year: 2007,
    user: 'Bob Averill',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/cereal-guy',
  },
  '32_cerealguyspit': {
    name: 'Cereal guy (spitting)',
    year: 2007,
    user: 'Bob Averill',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/cereal-guy',
  },
  '33_newspaperdad': {
    name: 'Newspaper guy / Rage Comics father',
    year: 2008,
    user: 'Anonymous',
    platform: '4chan (?)',
    link: '',
  },
  '34_cerealguytalking': {
    name: 'Cereal guy (talking)',
    year: 2007,
    user: 'Bob Averill',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/cereal-guy',
  },
  '35_cerealguybeer': {
    name: 'Cereal guy (beer)',
    year: 2008,
    user: 'Bob Averill',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/cereal-guy',
  },
  '36_fuck-yeah': {
    name: 'Fuck Yea guy',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fuck-yea',
  },
  '37_fuck-yea-close-enough': {
    name: 'Fuck Yea guy (close enough)',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fuck-yea',
  },
  '38_datass': {
    name: 'Dat Ass',
    year: '2010 (?)',
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fuck-yea',
  },
  '39_fuck-yea-like-a-boss': {
    name: 'Fuck Yea guy (like a boss)',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fuck-yea',
  },
  '40_fuck-yea-duck-yea': {
    name: 'Duck Yea',
    year: '2010 (?)',
    user: 'Anonymous',
    platform: '4chan (?)',
    link: 'https://meme.fandom.com/wiki/Duck_Yeah',
  },
  '41_determined-fumanchu-computer-stare': {
    name: 'Computer guy',
    year: 2011,
    user: '_hellojello',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/computer-reaction-faces',
  },
  '42_surprised-long-neck': {
    name: 'Long Neck Reaction guy (LNRG)',
    year: 2009,
    user: 'Anonymous',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/long-neck-reaction-guy',
  },
  '43_ba-dum-tss': {
    name: 'Rimshot (Ba Dum Tss)',
    year: 'Unknown',
    user: 'Anonymous',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/rimshot-ba-dum-tss',
  },
  '44_angry-desk-flip': {
    name: 'Angry Table Flip',
    year: 2011,
    user: 'Anonymous',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/flipping-tables-%E2%95%AF%E2%96%A1%E2%95%AF%EF%B8%B5-%E2%94%BB%E2%94%81%E2%94%BB',
  },
  '45_angry-mom': {
    name: 'Angry mom or Rage \nMomma',
    year: 2012,
    user: 'ocay',
    platform: 'Meme Center',
    link: '',
  },
  '46_me-gusta-creepy': {
    name: 'Me Gusta guy (creepy)',
    year: 2010,
    user: 'Insert',
    platform: '4chan/Reddit',
    link: 'https://knowyourmeme.com/memes/me-gusta',
  },
  '47_angry-no': {
    name: 'NO rage face',
    year: 2010,
    user: 'Anonymous',
    platform: 'Cheat Engine Forums',
    link: 'https://knowyourmeme.com/memes/no-rage-face',
  },
  '48_me-gusta-no-me-gusta': {
    name: 'Me Gusta no me gusta',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan/Reddit',
    link: 'https://knowyourmeme.com/memes/me-gusta',
  },
  '49_me-gusta-indeed': {
    name: 'Me Gusta indeed',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan/Reddit',
    link: 'https://knowyourmeme.com/memes/me-gusta',
  },
  '50_me-gusta-derp': {
    name: 'Me Gusta derp',
    year: 2010,
    user: 'Anonymous',
    platform: '4chan/Reddit',
    link: 'https://knowyourmeme.com/memes/me-gusta',
  },
  '51_laughing-lol-crazy-clean': {
    name: 'LOL guy',
    year: 2010,
    user: 'Anonymous',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/lol-guy',
  },
  '52_fuck-that-bitch-forever-yao-ming': {
    name: 'Fuck that bitch forever / Yao Ming',
    year: '2010 (?)',
    user: 'Anonymous',
    platform: '4chan/Reddit (?)',
    link: 'https://knowyourmeme.com/memes/yao-ming-face-bitch-please',
  },
  '53_fap-gentleman': {
    name: 'Fap guy (gentleman)',
    year: 2011,
    user: 'Anonymous',
    platform: '4chan/Reddit (?)',
    link: 'https://knowyourmeme.com/memes/fap-guy',
  },
  '54_fap-now-kiss': {
    name: '"Now kiss!" guy',
    year: 2011,
    user: 'King Gawjuss',
    platform: 'FunnyJunk',
    link: 'https://knowyourmeme.com/memes/now-kiss',
  },
  '55_sad-forever-alone': {
    name: 'Forever Alone guy',
    year: 2010,
    user: 'Azuul',
    platform: 'FunnyJunk',
    link: 'https://knowyourmeme.com/memes/forever-alone',
  },
  '56_motherofgod': {
    name: 'Mother of Good',
    year: 2008,
    user: 'KC Green / TehEman',
    platform: 'FunnyJunk',
    link: 'https://knowyourmeme.com/memes/mother-of-god',
  },
  '57_disgusted-oh-god': {
    name: 'Disgusted Oh God',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '58_rage-mega-rage': {
    name: 'OMG Rage Face (Ashraf face)',
    year: 2010,
    user: 'Ashraf',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/oh-crap-omg-rage-face',
  },
  '59_ohgodwhy': {
    name: 'Oh God Why',
    year: 2011,
    user: 'Diamondhead',
    platform: 'FunnyJunk',
    link: 'https://knowyourmeme.com/memes/oh-god-why',
  },
  '60_angry-eternal-contempt': {
    name: 'Angry Eternal Contempt',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '61_crafty-daily-lie': {
    name: 'Crafty Daily Lie',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '62_happy-your-pain-it-amuses-me': {
    name: '"Your pain, it amuses me"',
    year: '2012 (?)',
    user: '',
    platform: 'Imgur/Reddit',
    link: 'https://www.reddit.com/r/fffffffuuuuuuuuuuuu/comments/qqq1a/your_pain_it_amuses_me/',
  },
  '63_dogrageface': {
    name: 'Dog Rage Face',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '64_troll-face-origin': {
    name: 'Trollface - Original webcomic',
    year: 2008,
    user: 'Carlos Ramirez "Whynne"',
    platform: 'DeviantART',
    link: 'https://knowyourmeme.com/memes/trollface',
  },
  '65_fuck-yea-origin': {
    name: 'Fuck yeah - Original panel',
    year: 2010,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/fuck-yea',
  },
  '66_epic-fail-meets-the-internet': {
    name: 'Epic Fail Guy (EFG) meets the Internet',
    year: 2006,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/epic-fail-guy',
  },
  '67_herp-derp': {
    name: 'Rage Comic FFUU (Herp Darp origin)',
    year: 2010,
    user: 'ULJarad',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/harp-darp-herp-derp',
  },
  '68_derp-bitch-please': {
    name: 'Derp and Yao Ming',
    year: 2011,
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '69_shit-posting-king': {
    name: 'The Shitposting King',
    year: 2014,
    user: 'Hardycoreman',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/shitposting',
  },
  '70_i-know-that-feel-bro': {
    name: 'I Know that Feel Bro',
    year: 2010,
    user: 'Wojak',
    platform: 'Krautchan',
    link: 'https://knowyourmeme.com/memes/i-know-that-feel-bro',
  },
  '71_misc-clean-all-the-things': {
    name: 'All the things',
    year: 2010,
    user: 'Allie Brosh',
    platform: '',
    link: 'https://knowyourmeme.com/memes/all-the-things',
  },
  '72_bro-fist': {
    name: 'Derp, Derpina and Spider',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '73_la-vida-antes-del-telefono-movil': {
    name: 'La vida antes del teléfono móvil',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '01_ceilingcat': {
    name: 'Ceiling Cat',
    year: 2006,
    user: '',
    platform: 'Encyclopedia Dramatica / Something Awful / 4chan',
    link: 'https://knowyourmeme.com/memes/ceiling-cat',
  },
  '02_icanhascheezburger': {
    name: 'Happy Cat "I Can Has Cheezburger?"',
    year: 2003,
    user: 'FancyCat (unconfirmed)',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/happy-cat',
  },
  '03_longcat': {
    name: 'Longcat Apocalypse',
    year: 2006,
    user: '',
    platform: 'Futaba Channel (2chan)',
    link: 'https://knowyourmeme.com/memes/longcat',
  },
  '04_grumphycat': {
    name: 'Grumpy Cat',
    year: 2012,
    user: 'Tabatha and Brian Bundesen',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/grumpy-cat',
  },
  '05_hatersgonnacat': {
    name: 'Haters Gonna Hate',
    year: 2009,
    user: '',
    platform: 'YayHooray.com',
    link: 'https://knowyourmeme.com/memes/haters-gonna-hate',
  },
  '06_couragewolf': {
    name: 'Courage Wolf',
    year: 2006,
    user: '',
    platform: '4chan (?)',
    link: 'https://knowyourmeme.com/memes/courage-wolf',
  },
  '07_y-u-no': {
    name: 'Y U NO guy',
    year: 2010,
    user: '',
    platform: 'Tumblr',
    link: 'https://knowyourmeme.com/memes/y-u-no-guy',
  },
  '08_mad-wolf': {
    name: 'Insanity Wolf',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/insanity-wolf',
  },
  '09_forever-alone-image-macro': {
    name: 'Forever Alone guy "Make dinner for two..."',
    year: 2010,
    user: '',
    platform: '4chan/Reddit/FunnyJunk',
    link: 'https://knowyourmeme.com/memes/forever-alone',
  },
  '10_mad-wolf-harder': {
    name: 'Insanity Wolf "Harder"',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/insanity-wolf',
  },
  '11_socially-awkward-penguin': {
    name: 'Socially Awkward Penguin',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/socially-awkward-penguin',
  },
  '12_socially-awkward-penguin2': {
    name: 'Socially Awkward Penguin "You wave back"',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/socially-awkward-penguin',
  },
  '13_socially-awesome-awkward-penguin': {
    name: 'Socially Awesome Awkward Penguin',
    year: '2009-2010',
    user: '',
    platform: 'Tumblr/4chan',
    link: 'https://knowyourmeme.com/memes/socially-awkward-penguin#sociallyawesome',
  },
  '14_socially-awesome-penguin': {
    name: 'Socially Awesome Penguin',
    year: '2009-2011',
    user: '',
    platform: 'Tumblr',
    link: 'https://knowyourmeme.com/memes/socially-awkward-penguin#sociallyawesome',
  },
  '15_socially-awesome-average-awkward-penguin-chart': {
    name: 'Socially Awkward/Average/Awesome Penguin Chart',
    year: '2009-2012',
    user: '',
    platform: 'Tmblr/4chan/Reddit/various',
    link: 'https://knowyourmeme.com/memes/socially-awkward-penguin#sociallyawesome',
  },
  '16_futurama-fry-not-sure-if': {
    name: 'Futurama Fry / Not Sure If',
    year: 2011,
    user: '',
    platform: 'Reddit/various',
    link: 'https://knowyourmeme.com/memes/futurama-fry-not-sure-if',
  },
  '17_success-kid': {
    name: 'Succes Kid / I Hate Sandcastles',
    year: 2007,
    user: '',
    platform: 'Flickr/various',
    link: 'https://knowyourmeme.com/memes/success-kid-i-hate-sandcastles',
  },
  '18_wat': {
    name: 'wat',
    year: 2003,
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/wat',
  },
  '19_success-kid-button': {
    name: 'Success Kid "Push a button"',
    year: 2007,
    user: '',
    platform: 'Flickr/various',
    link: 'https://knowyourmeme.com/memes/success-kid-i-hate-sandcastles',
  },
  '20_futurama-fry-not-sure-if-trolling': {
    name: 'Futurama Fry / Not Sure If Trolling',
    year: '2012 (?)',
    user: '',
    platform: 'Reddit/various',
    link: 'https://knowyourmeme.com/memes/futurama-fry-not-sure-if',
  },
  '21_overly-attached-girlfriend': {
    name: 'Overly Attached Girlfriend',
    year: 2012,
    user: 'wzr0713',
    platform: 'YouTube',
    link: 'https://knowyourmeme.com/memes/overly-attached-girlfriend',
  },
  '22_conspiracy-keanu-origin': {
    name: 'Conspiracy Keanu (original)',
    year: 2011,
    user: '',
    platform: 'FunnyJunk/Reddit',
    link: 'https://knowyourmeme.com/memes/conspiracy-keanu',
  },
  '23_bad-luck-brian': {
    name: 'Bad Luck Brian',
    year: 2012,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/bad-luck-brian',
  },
  '240_condescending-wonka-creepy-wonka': {
    name: 'Condescending Wonka / Creepy Wonka',
    year: 2010,
    user: '',
    platform: 'Quickmeme',
    link: 'https://knowyourmeme.com/memes/condescending-wonka-creepy-wonka',
  },
  '241_drive-into-mordor': {
    name: 'One Does Not Simply Drive into Mordor',
    year: 2000,
    user: '',
    platform: 'Something Awful',
    link: 'https://knowyourmeme.com/memes/one-does-not-simply-walk-into-mordor',
  },
  '25_scumbag-steve': {
    name: 'Scumbag Steve',
    year: 2011,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/scumbag-steve',
  },
  '26_good-guy-greg': {
    name: 'Good Guy Greg',
    year: 2011,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/good-guy-greg',
  },
  '27_[10]-guy': {
    name: '[10] Guy',
    year: 2011,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/10-guy',
  },
  '28_scumbag-steve-car': {
    name: 'Scumbag Steve "Asks to borrow car"',
    year: 2011,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/scumbag-steve',
  },
  '29_scumbag-steve-everything': {
    name: 'Scumbag Steve "Hey can I borrow"',
    year: 2011,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/scumbag-steve',
  },
  '30_conspiracy-kenue-[10]-guy': {
    name: 'Conspiracy [10] Guy',
    year: 2011,
    user: '',
    platform: 'Reddit r/funny',
    link: 'https://knowyourmeme.com/memes/conspiracy-keanu',
  },
  '31_scumbag--guy-greg': {
    name: 'Scumbag Guy Greg',
    year: 'Unknown',
    user: '',
    platform: 'Reddit (?)',
    link: '',
  },
  '32_scumbag-guy-greg': {
    name: 'Scumbag Guy Greg',
    year: 'Unknown',
    user: '',
    platform: 'Reddit (?)',
    link: '',
  },
  '33_bad-luck-greg': {
    name: 'Bad Luck Scumbag Greg',
    year: 'Unknown',
    user: '',
    platform: 'Reddit (?)',
    link: '',
  },
  '34_good-[10]-guy': {
    name: 'Good [10] Guy',
    year: 'Unknown',
    user: '',
    platform: 'Reddit (?)',
    link: '',
  },
  '35_memes-everywhere': {
    name: 'Memes, Memes Everywhere',
    year: 2010,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/x-x-everywhere',
  },
  '36_get-to-the-charga': {
    name: 'Get to the charga (Arnold "Stop Whining")',
    year: '2009 (?)',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/stop-whining',
  },
  '37_acid-viking': {
    name: 'Acid Viking',
    year: '2012 (?)',
    user: '',
    platform: 'Cheezburger.com',
    link: 'https://cheezburger.com/5935540480/the-most-boring-trip',
  },
  '38_ps1': {
    name: 'PlayStation 1 "If you remember this"',
    year: 'Unknown',
    user: '',
    platform: 'Reddit (?)',
    link: '',
  },
  '39_gordon-ramsay-stuck': {
    name: 'Gordon Ramsey Yelling damned loudly',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '43_chubby-bubbles-girl': {
    name: 'Chubby Bubbles Girl - Jurassic Parc',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/chubby-bubbles-girl',
  },
  '44_chubby-bubbles-girl-egg': {
    name: 'Chubby Bubbles Girl - Egg',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/chubby-bubbles-girl',
  },
  '45_disaster-girl-wtc-photo-plane': {
    name: 'Disaster Girl',
    year: 2007,
    user: '',
    platform: 'Zooomr/JPG Magazine/Various',
    link: 'https://knowyourmeme.com/memes/disaster-girl',
  },
  '46_scarlett-johansson-falling-down': {
    name: 'Scarlett Johansson Falling Down',
    year: 2013,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/scarlett-johansson-falling-down',
  },
  '47_icecream-man': {
    name: 'Ice Cream Man',
    year: 'Unknown',
    user: '',
    platform: 'Reddit',
    link: 'https://www.reddit.com/r/photoshopbattles/comments/7ok7rg/psbattle_a_man_escaping_with_his_ice_cream/',
  },
  '48_xzibit': {
    name: 'Xzibit Yo Dawg "I heard you like cars"',
    year: 2007,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/xzibit-yo-dawg',
  },
  '49_michael-stop-it-get-some-help': {
    name: 'Stop it, get some help',
    year: '1987-2010',
    user: '',
    platform: 'YouTube',
    link: 'https://knowyourmeme.com/memes/stop-it-get-some-help',
  },
  '50_xzibit-yo-dawg-thats-lame': {
    name: 'Xzibit Yo Dawg',
    year: 2007,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/xzibit-yo-dawg',
  },
  '51_mom-please-go-away-lemme-investigate-in-peace': {
    name: 'Mom Please Go Away',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '52_morpheus': {
    name: 'Matrix Morpheus "What if I told you"',
    year: 2012,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/matrix-morpheus',
  },
  '54_crying-dawson': {
    name: 'Crying Dawson "Someone\'s on the phone"',
    year: 2006,
    user: '',
    platform: 'YouTube',
    link: 'https://knowyourmeme.com/memes/dawson-crying',
  },
  '55_dancing-black-kids': {
    name: 'African Children Dancing "First to see Lion King"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '56_first-world-problems': {
    name: 'First World Problems "Someone on the Internet"',
    year: 2011,
    user: '',
    platform: 'Tumblr/Various',
    link: 'https://knowyourmeme.com/memes/first-world-problems',
  },
  '57_sceptical-third-world-child': {
    name: 'Third World Skeptical Kid "So you are telling me"',
    year: 2012,
    user: '',
    platform: 'Reddit/Imgur',
    link: 'https://www.bbc.com/news/blogs-trending-34678592',
  },
  '58_launch-i-said-lunch': {
    name: 'Launch? I said lunch',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/people/kim-jong-un',
  },
  '59_first-world-problems-vintage': {
    name: '1890s Problems "Telegraphed boyfriend"',
    year: 2012,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/first-world-problems',
  },
  '60_ola-k-ase': {
    name: 'Ola K Ase',
    year: 2012,
    user: '',
    platform: 'Forocoches',
    link: 'https://knowyourmeme.com/memes/ola-k-ase',
  },
  '61_i-just-fly-the-drone': {
    name: 'Cup of Joe "What\'s the difference"',
    year: '2015 (?)',
    user: '',
    platform: 'Unkown',
    link: '',
  },
  '62_hi-im-the-cia': {
    name: 'Troy McClure "Hi I\'m the CIA"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/subcultures/the-simpsons',
  },
  '63_sick-of-government': {
    name: 'Department of Homeland Security "Sick of the Government"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '64_doge-original': {
    name: 'Doge (original)',
    year: 2013,
    user: '',
    platform: 'Tumblr',
    link: 'https://knowyourmeme.com/memes/doge',
  },
  '65_doge-impact': {
    name: 'Ironic Doge',
    year: 2017,
    user: '',
    platform: 'Facebook',
    link: 'https://knowyourmeme.com/memes/ironic-doge-memes',
  },
  '66_randon-oil-tankers': {
    name: 'Ironic Doge "Oil tankers"',
    year: 2019,
    user: '',
    platform: 'Reddit/Facebook',
    link: 'https://knowyourmeme.com/memes/ironic-doge-memes',
  },
  '67_doge-impact-controversy': {
    name: 'Ironic Doge "Controversy"',
    year: '2017 (?)',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/ironic-doge-memes',
  },
  '68_karen-you-horrific-wench': {
    name: 'Ironic Doge "Karen you horrific wench"',
    year: '2017 (?)',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/ironic-doge-memes',
  },
  '69_le-toucan': {
    name: 'Ironic Doge "Le toucan"',
    year: '2017 (?)',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/ironic-doge-memes',
  },
  '70_doges': {
    name: 'Doge + Doggos',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/doggo',
  },
  '71_doge-life-on-earth': {
    name: 'Doge "It is time"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '72_i-should-go-to-spain': {
    name: 'Fancy Cat (I should buy a boat cat) "I should go to Spain"',
    year: 2012,
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/i-should-buy-a-boat-cat',
  },
  '73_marathons': {
    name: 'You do marathons?',
    year: '2014 (?)',
    user: '',
    platform: 'Facebook',
    link: 'https://knowyourmeme.com/memes/subcultures/game-of-thrones',
  },
  '74_shut-up-liver': {
    name: 'Shut up liver',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '75_what-do-we-want': {
    name: 'Charlottesville "What do we want?"',
    year: 2017,
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/events/2017-unite-the-right-rally',
  },
  '76_pictures-of-spiderman': {
    name: 'What can I get you',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '78_do-we-smoke-him': {
    name: 'Medieval Image Macro "Now what?"',
    year: '2019 (?)',
    user: '',
    platform: 'Reddit (?)',
    link: 'https://knowyourmeme.com/memes/medieval-tapestry-edits',
  },
  '79_pope': {
    name: 'Pope Francis "Two Fingers"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/people/pope-francis',
  },
  '80_jesus': {
    name: 'Jesus is a Jerk "Tumor"',
    year: '2009 (?)',
    user: '',
    platform: 'Forums/Various',
    link: 'https://knowyourmeme.com/memes/jesus-is-a-jerk',
  },
  '81_monsters': {
    name: 'Ratzinger blessing Hawking "When lights blackout"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/people/stephen-hawking',
  },
  '82_me-about-to': {
    name: 'Fishing naked "Me about to..."',
    year: '2013 (?)',
    user: '',
    platform: 'FunnyJunk/Twitter (?)',
    link: '',
  },
  '83_sick-bastard': {
    name: "I'd like the porn in my room to be disabled",
    year: '2017 (?)',
    user: '',
    platform: 'Unknown',
    link: '',
  },
  '84_any-questions': {
    name: 'Any questions?',
    year: 2019,
    user: '',
    platform: 'Reddit',
    link: 'https://www.reddit.com/r/memes/comments/9p0rt6/any_questions/',
  },
  '85_american-chopper-argument-impact': {
    name: 'American Chopper Argument "The autopsy"',
    year: '2009-2011',
    user: '',
    platform: 'Reddit',
    link: 'https://knowyourmeme.com/memes/american-chopper-argument',
  },
  '86_dank-doctor-impact': {
    name: 'Doctor-patient "Good news"',
    year: 'Unknown',
    user: '',
    platform: 'imgflip',
    link: '',
  },
  '87_john-watt': {
    name: 'John Watt',
    year: 2019,
    user: '',
    platform: 'Reddit',
    link: 'https://www.reddit.com/r/memes/comments/9tswze/john_watt/',
  },
  '88_when-laugh-at-meme-you-with-impact-font-ironic-meme': {
    name: 'When You Laugh at Meme With Impact Font',
    year: 2017,
    user: '',
    platform: 'Facebook',
    link: 'https://knowyourmeme.com/memes/dank-memes',
  },
  '89_E': {
    name: 'Lord Marquaad E - Deep Fried Version',
    year: 2018,
    user: '',
    platform: 'Tumblr',
    link: 'https://knowyourmeme.com/memes/lord-marquaad-e',
  },
  '001_i-know-that-feel-bro': {
    name: 'I Know that Feel Bro',
    year: 2010,
    user: 'Wojak',
    platform: 'Krautchan',
    link: 'https://knowyourmeme.com/memes/i-know-that-feel-bro',
  },
  '002_we-all-know-that-feel': {
    name: 'We all know that Feel',
    year: 2010,
    user: '',
    platform: '4chan/various',
    link: 'https://knowyourmeme.com/memes/feels',
  },
  '003_born-to-feel': {
    name: 'Wojak (Feels guy) "Born to feel"',
    year: 2010,
    user: '',
    platform: 'Vichan',
    link: 'https://knowyourmeme.com/memes/wojak-feels-guy',
  },
  '004_why-cant-i-hold-all-these-feels': {
    name: 'Wojak (Feels guy) "Why can\'t I hold all these feels"',
    year: 2010,
    user: '',
    platform: '4chan/reddit',
    link: 'https://memepediadankmemes.fandom.com/wiki/Wojak',
  },
  '005_its-an-abstract-kind-of-feel': {
    name: 'Meme Man "It\'s an abstract kind of feel"',
    year: 2014,
    user: '',
    platform: 'Facebook (?)',
    link: 'https://knowyourmeme.com/memes/meme-man',
  },
  '006_pepe-feels-good-man': {
    name: 'Feels Good Man (original)',
    year: 2008,
    user: 'Matt Fury',
    platform: "Boy's Town (comic)",
    link: 'https://knowyourmeme.com/memes/feels-good-man',
  },
  '007_pepe-feels-good-man': {
    name: 'Pepe "Feels Good Man" (Yoshi)',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/pepe-the-frog',
  },
  '008_i-m-very-pleased-with-myself': {
    name: 'Pepe "I\'m very pleased with myself"',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://rare-pepe.com/',
  },
  '009_pepe-feels-bad-man': {
    name: 'Pepe "Feels Bad Man" (Sad Frog)',
    year: 2009,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/feels-bad-man-sad-frog',
  },
  '010_pepe-feels-good-man-3d-beuty': {
    name: 'Pepe 3D "Feels Good Man"',
    year: 'Unknown',
    user: '',
    platform: '4chan (?)',
    link: 'https://rare-pepe.com/',
  },
  '011_wojak-big-brain-universe': {
    name: 'Wojak Big Brain Universe',
    year: '2016-2017',
    user: '',
    platform: '4chan',
    link: 'https://nymag.com/intelligencer/2017/02/whomst-is-the-smartest-on-4chan.html',
  },
  '012_wojak-riding-brain-elephant-space': {
    name: 'Wojak riding Big Brain Elefant',
    year: '2016-2017',
    user: '',
    platform: '4chan',
    link: 'https://nymag.com/intelligencer/2017/02/whomst-is-the-smartest-on-4chan.html',
  },
  '013_wojak-squat-night-sky': {
    name: 'Wojak squatting under the night sky',
    year: 'Unknown',
    user: '',
    platform: '4chan (?)',
    link: 'https://knowyourmeme.com/memes/wojak-feels-guy',
  },
  '014_wojak-brandyfied': {
    name: 'All fun and games until life loses meaning',
    year: '2019 (?)',
    user: '',
    platform: '4chan/reddit',
    link: 'https://www.reddit.com/r/nihilism/comments/bmjfvo/everybody_gangsta_until_the_void_stares_back/',
  },
  '015_wojak-suicidal': {
    name: 'Days without suicidal thoughts: 0',
    year: '2017-2018',
    user: '',
    platform: '4chan/reddit',
    link: 'https://www.reddit.com/r/2meirl4meirl/comments/77yf8g/2meirl4meirl/',
  },
  '016_angry-pepe': {
    name: 'REEEEEEE',
    year: 2014,
    user: '',
    platform: '/r9k/',
    link: 'https://knowyourmeme.com/memes/reeeeeee',
  },
  '017_happy-pepe-wholesome': {
    name: 'Wholesome Pepe',
    year: '2018 (?)',
    user: '',
    platform: '4chan/reddit',
    link: 'https://www.theguardian.com/technology/2016/aug/13/wholesome-memes-could-they-mean-more-good-times-online',
  },
  '018_wojaks-father-pepe': {
    name: 'WLYwYLC',
    year: 2015,
    user: '',
    platform: '4chan/reddit',
    link: 'https://rare-pepe.com/rare-pepe-collection/0521_-_wlywylc/',
  },
  '019_pepe-earthworm': {
    name: 'Smug Worm Pepe',
    year: 2016,
    user: '',
    platform: '/r9k/',
    link: 'https://knowyourmeme.com/memes/rare-pepe',
  },
  '020_pepe-order-cult': {
    name: 'Guild Navigator Pepe',
    year: '2016 (?)',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://en.wikipedia.org/wiki/Guild_Navigator',
  },
  '021_double-pepe': {
    name: 'Symmetrical Pepe',
    year: '2015-2017',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/rare-pepe',
  },
  '022_rare-pepe-wojak-egyptian': {
    name: 'Egyptian Sojak and Pepe',
    year: '2015-2017',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/rare-pepe',
  },
  '023_magic-genious-pepe': {
    name: 'Pepe Genie',
    year: '2015-2017',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/smug-frog',
  },
  '024_rare-smug-pepe': {
    name: 'Rare Smug Pepe',
    year: '2015-2017',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/rare-pepe',
  },
  '025_rare-golden-pepe': {
    name: 'Rare Golden Wojak',
    year: '2015-2017',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/rare-pepe',
  },
  '026_yin-yang-pepe-wojak': {
    name: 'Pepe-Wojak Ying-Yang',
    year: '2015-2017 (?)',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://www.youtube.com/watch?v=r_OuJWUWmR4',
  },
  '027_wrecking-ball-pepe-wojak': {
    name: 'Pepe-Wojak Wrecking Ball',
    year: 2016,
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://www.youtube.com/watch?v=tbdRIv3uYrg',
  },
  '028_pepe-wojak-fist': {
    name: 'Wojak Punch',
    year: '2015-2017 (?)',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/pepe-punch',
  },
  '029_triheaded-wojak': {
    name: 'Threeheaded Wojak',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/wojak-feels-guy',
  },
  '030_wholesome-pepe-wojak': {
    name: 'Wholesome Pepe and Wojak',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit (?)',
    link: 'https://knowyourmeme.com/memes/wholesome-memes',
  },
  '031_mcdonalds-pepe-wojak': {
    name: 'Wageslave Wojak (Wagie)',
    year: 2015,
    user: '',
    platform: '4chan/',
    link: 'https://knowyourmeme.com/memes/wagie',
  },
  '032_smug-pepe-poor-wojak': {
    name: 'Smug Pepe and Poor Wojak',
    year: 'Unknown',
    user: '',
    platform: '4chan/warosu',
    link: 'https://warosu.org/biz/thread/12759905#p12760373',
  },
  '033_forced-corp-smug-wojak-pepe-fresco-npc': {
    name: 'Forced Corp',
    year: 2018,
    user: '',
    platform: '4chan/warosu',
    link: 'https://medium.com/memewarweekly/when-forced-memes-backfire-d41a22d6336d',
  },
  '034_pepe-the-frog-universe-fuck': {
    name: 'Pepe Wojak Leviticus',
    year: '2017 (?)',
    user: '',
    platform: '4chan (?)',
    link: 'https://i-d.vice.com/en_us/article/vb5bz9/alt-right-meme-indoctrinating-gen-z-instagram-tiktok',
  },
  '035_holy-pepe-bless-crying-wojak': {
    name: 'Holy Pepe blessing Crying Wojak',
    year: '2017 (?)',
    user: '',
    platform: '4chan (?)',
    link: 'https://www.huffpost.com/entry/matt-furie-pepe-frog-meme_n_57ed3a6fe4b0c2407cdc4298',
  },
  '036_holy-warrior-wojak': {
    name: "Wojak D'Arc",
    year: '2017 (?)',
    user: '',
    platform: '4chan (?)',
    link: 'https://en.wikipedia.org/wiki/Wojak',
  },
  '037_indoeuropeans-wojak': {
    name: 'Old Europeans VS Indo Europeans',
    year: '2020 (?)',
    user: '',
    platform: '4chan/Facebook/Various',
    link: 'https://en.wikipedia.org/wiki/Proto-Indo-Europeans',
  },
  '038_wojak-pepe-army': {
    name: 'Feelception Army',
    year: '2014 (?)',
    user: '',
    platform: '4chan (?)',
    link: 'https://me.me/t/crying-wojak-mask-meme?s=new',
  },
  '039_bf_wojak-pepe': {
    name: 'Wojak with Pepe Mask throwing a Brick',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit/various',
    link: 'https://www.memeatlas.com/pepe-memes.html',
  },
  '040_hell-pepe-wojak': {
    name: 'Pepehell',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit/various',
    link: 'https://rare-pepe.com/',
  },
  '041_potato-pepe': {
    name: 'Potato Pepe',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit/various',
    link: 'https://www.memeatlas.com/pepe-memes.html',
  },
  '043_poorly-drawn-pepe': {
    name: 'Poorly Drawn Sad Pepe',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit/various',
    link: 'https://www.memeatlas.com/pepe-memes.html',
  },
  '044_poorly-drawn-small-pepe': {
    name: 'Poorly Drawn Small Pepe',
    year: 'Unknown',
    user: '',
    platform: '4chan/reddit/various',
    link: 'https://rare-pepe.com/',
  },
  '045_apu-apustaja-potter-derivative': {
    name: 'Apu Apustaja Harry Potter Caricature',
    year: 2016,
    user: '',
    platform: 'Ylilauta',
    link: 'https://knowyourmeme.com/memes/apu-apustaja',
  },
  '046_apu-apustaja-in-space': {
    name: 'Apu Apustaja in Space',
    year: 2016,
    user: '',
    platform: 'Ylilauta',
    link: 'https://knowyourmeme.com/memes/apu-apustaja',
  },
  '047_pink-wojak-buddy': {
    name: 'Pink Wojak Buddy',
    year: 2017,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pink-wojak',
  },
  '048_pink-wojak-creatura': {
    name: 'Pink Wojak Creature',
    year: 2017,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pink-wojak',
  },
  '049_pink-wojak-vertical-glitch': {
    name: 'Pink Wojak Vertical Glitch',
    year: 2017,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pink-wojak',
  },
  '050_pink-wojak-head-splitting': {
    name: 'Pink Wojak Head Splitting',
    year: 2017,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pink-wojak',
  },
  '051_pink-wojak-aztek': {
    name: 'Pink Wojak Aztek Drawing',
    year: 2017,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pink-wojak',
  },
  '052_lord-kek': {
    name: 'Lord Kek Trading Card',
    year: '2015-2017',
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/cult-of-kek',
  },
  '053_pepe-the-troll': {
    name: 'Pepe the Troll',
    year: '2015-2017',
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/cultures/trolling',
  },
  '054_fair-pepe': {
    name: 'Fair Pepe',
    year: '2015-2017',
    user: '',
    platform: '4chan',
    link: 'https://www.theatlantic.com/politics/archive/2016/09/its-not-easy-being-green/499892/',
  },
  '055_spc-pepe-wojak': {
    name: 'First Memes in Space',
    year: '2015-2017',
    user: '',
    platform: '4chan',
    link: 'http://thephilosophersmeme.com/2016/01/29/the-post-pepe-manifesto/',
  },
  '056_beatles-pepe': {
    name: 'The Pepe Beatles',
    year: '2015-2017',
    user: '',
    platform: '4chan (?)',
    link: 'https://wakespace.lib.wfu.edu/bitstream/handle/10339/90765/Riemensperger_wfu_0248M_11234.pdf',
  },
  '057_safety-bear': {
    name: 'Safety Bear Seal',
    year: 2003,
    user: '',
    platform: '4chan/various',
    link: 'https://knowyourmeme.com/memes/seals-of-approval',
  },
  '058_pedo-bear': {
    name: 'Pedobear',
    year: 2004,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pedobear',
  },
  '059_pedo-bear-trap': {
    name: 'Pedobear Trap (Free Candy)',
    year: 2004,
    user: '',
    platform: '4chan',
    link: 'https://knowyourmeme.com/memes/pedobear',
  },
  '060_pedo-bear-shining-tweens': {
    name: 'Pedobear Shining Twins',
    year: 2004,
    user: '',
    platform: '4chan',
    link: 'https://en.wikipedia.org/wiki/Pedobear',
  },
  '061_pedo-bear-bliss': {
    name: 'Pedobear Bliss',
    year: 2004,
    user: '',
    platform: '4chan',
    link: 'http://ftpcontent.worldnow.com/griffin/NEWSon6/PDF/1009/PedoBear%20PUBLIC%20INFO%20Bulletin%5B1%5D%5B1%5D.pdf',
  },
  '062_spurdo-sparde-beta': {
    name: 'Spurdo Spärde',
    year: 2009,
    user: '',
    platform: 'Kuvalauta/Ylilauta',
    link: 'https://knowyourmeme.com/memes/spurdo-sparde',
  },
  '063_aligersantti-spurdo': {
    name: 'Aligersantti Spurdo',
    year: 2011,
    user: '',
    platform: 'Ylilauta',
    link: 'https://meemi.info/w/Aligersantti_Spurdo',
  },
  '064_luokenvelvoja-spurdo': {
    name: 'Luokenvelvoja Spurdo',
    year: '2011 (?)',
    user: '',
    platform: 'Ylilauta',
    link: 'https://knowyourmeme.com/memes/spurdo-sparde',
  },
  '065_american-bear': {
    name: 'American Bear (Spurdo Bear)',
    year: 2012,
    user: '',
    platform: 'Ylilauta',
    link: 'https://knowyourmeme.com/memes/american-bear',
  },
  '066_lugiolainen-sparde': {
    name: 'Lugiolainen Sparde',
    year: '2012 (?)',
    user: '',
    platform: 'Ylilauta (?)',
    link: 'https://www.buzzfeednews.com/article/josephbernstein/how-a-leftist-finnish-meme-became-a-white-supremacist-comic',
  },
  '067_sad-spurdo': {
    name: 'Sad Spurdo',
    year: '2012 (?)',
    user: '',
    platform: 'Ylilauta (?)',
    link: 'https://knowyourmeme.com/memes/dolan',
  },
  '068_bird-spurdo': {
    name: 'Bird Spurdo',
    year: '2012 (?)',
    user: '',
    platform: 'Ylilauta (?)',
    link: 'https://knowyourmeme.com/memes/spurdo-sparde',
  },
  '069_spurdo-owl': {
    name: 'Spurdo Owl',
    year: '2012 (?)',
    user: '',
    platform: 'Ylilauta (?)',
    link: 'https://knowyourmeme.com/memes/spurdo-sparde',
  },
  '070_elephant': {
    name: 'Spurdo Elephant',
    year: '2012 (?)',
    user: '',
    platform: 'Ylilauta (?)',
    link: 'https://knowyourmeme.com/memes/spurdo-sparde',
  },
  '071_elephant-origin': {
    name: 'Elephant Origin Story',
    year: 'Unknown',
    user: '',
    platform: 'Unknown',
    link: 'https://knowyourmeme.com/memes/cultures/webcomics',
  },
  '072_gondolaandanubis': {
    name: 'Gondola and Anubis',
    year: 2015,
    user: '',
    platform: 'Ylilauta',
    link: 'https://knowyourmeme.com/memes/gondola',
  },
  '073_creation-of-gondola_by-badassbunnyx': {
    name: 'The Creation of Gondola',
    year: 2015,
    user: '',
    platform: 'Ylilauta/various',
    link: 'https://hugelol.com/lol/473814/comment/2181814',
  },
  '074_gondola-diogenes': {
    name: 'Gondola Diogenes',
    year: 2015,
    user: '',
    platform: 'Ylilauta/various',
    link: 'https://thephilosophersmeme.com/2015/11/29/a-short-note-on-gondola/',
  },
  '075_gondola-at-the-slave-market': {
    name: 'Gondola at the Slavemarket',
    year: 2015,
    user: '',
    platform: 'Ylilauta/various',
    link: 'https://archive.nyafuu.org/wg/thread/7104590/',
  },
  '076_gondola-earth-porn': {
    name: 'Gondola Earthporn',
    year: 2015,
    user: '',
    platform: 'Ylilauta/various',
    link: 'https://wirechan.org/cf/res/18.html',
  },
  '077_the-world': {
    name: 'Gondola Tarot - XXI The World',
    year: 2018,
    user: 'dezzear',
    platform: 'Imgur',
    link: 'https://knowyourmeme.com/memes/tarot-arcana-parodies',
  },
  '078_the-fool': {
    name: 'Gondola Tarot - 0 The Fool',
    year: 2018,
    user: 'dezzear',
    platform: 'Imgur',
    link: 'https://knowyourmeme.com/memes/tarot-arcana-parodies',
  },
  '079_the-magician': {
    name: 'Gondola Tarot - I The Magician',
    year: 2018,
    user: 'dezzear',
    platform: 'Imgur',
    link: 'https://knowyourmeme.com/memes/tarot-arcana-parodies',
  },
  '080_the-weel-of-fortune': {
    name: 'Gondola Tarot - X The Wheel of Fortune',
    year: 2018,
    user: 'dezzear',
    platform: 'Imgur',
    link: 'https://knowyourmeme.com/memes/tarot-arcana-parodies',
  },
  '081_the-moon': {
    name: 'Gondola Tarot - XVIII The Moon',
    year: 2018,
    user: 'dezzear',
    platform: 'Imgur',
    link: 'https://knowyourmeme.com/memes/tarot-arcana-parodies',
  },
  '082_prison.': {
    name: 'Gondolas in Prison (Gondola School)',
    year: 2017,
    user: '',
    platform: 'Unknown',
    link: 'https://www.reddit.com/r/Gondola/',
  },
  '083_walk': {
    name: 'Gondola Walking',
    year: '2015-2018',
    user: '',
    platform: 'Unknown',
    link: 'https://imgur.com/r/Gondola/SDCmBwl',
  },
  '084_temple': {
    name: 'Gondola by the sea',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '085_space': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '086_universe': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '087_fsjal-wojak': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '088_cat-change-that-feel-wojak': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '089_happy-wojak': {
    name: "Sunset Wojak / We're all gonna make it",
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '090_sky-wojak': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '091_we-are-all-gonna-make-it-via-wojak-hole-IG': {
    name: '',
    year: '',
    user: 'via @wojakhole on Instagram',
    platform: '',
    link: '',
  },
  '092_wojak-npc-transition': {
    name: 'Wojak to NPC',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '093_wojak-npc-creature': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '094_npc-shower': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '095_frontal-npc-on-4chan': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '096_its-ok-to-be-gray-counternarrative': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '097_gritty-mascotte': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '098_good-night-alt-right-gritty': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '099_gritty-time': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '100_we-are-not-eating-OC': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '101_gritty-god-of-chaos': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '102_wojak_compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '103_wojak-masks': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '104_oomer-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '105_wojak-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '106_wojak-compass2': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '107_hyperstitional-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '108_hyperstitional-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '109_hyperstitional-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '110_hyperstitional-compass': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '111_multispecies': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '112_hyperstitional-compass': {
    name: '',
    year: '',
    user: 'via woodlousem (twitter)',
    platform: '',
    link: '',
  },
  '113_hyperstitional-compass': {
    name: '',
    year: '',
    user: 'via woodlousem (twitter)',
    platform: '',
    link: '',
  },
  '001_i-wish-i-was-at-home-wojak': {
    name: 'I wish i was at home Wojak',
    year: 2009,
    user: '',
    platform: 'Sad and Useless',
    link: 'https://knowyourmeme.com/memes/i-wish-i-was-at-home',
  },
  '002_i-wish-i.was-at-home-playing-gravelord': {
    name: 'I wish i was at home playing gravelord',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '003_i-wish-i-was-at-home-on-mfa': {
    name: 'I wish i was at home on mfa',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '004_complaining': {
    name: 'I wish i was at home on tumblr',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '005_all-emems-are-altright': {
    name: 'All memes are alt-right',
    year: '',
    user: '',
    platform: '',
    link: 'https://www.reddit.com/r/fantanoforever/comments/7508vi/this_party_sucks_i_wish_i_was_at_home_writing_for/',
  },
  '006_i-wish-i-was-at-home-abstract': {
    name: 'I wish i was at home abstract',
    year: '',
    user: '',
    platform: '',
    link: 'https://thememeschool.weebly.com/home/like-a-fish-out-of-water-i-wish-i-was-at-home',
  },
  '007_i-wish-i-was-at-home-teampunk': {
    name: 'I wish i was at home teampunk',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '008_i-wish-i-was-at-home-cybergoth': {
    name: 'I wish i was at home cybergoth',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '009_i-wish-i-was-at-home-medieval-wave': {
    name: 'I wish i was at home medieval wave',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '010_i-wish-i-was-at-home-real-deal': {
    name: 'I wish i was at home real deal',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '011_living-the-dream': {
    name: 'Wojak living the dream',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '012_no-gf-wojak': {
    name: 'No gf Wojak',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '013_doomer_wojak': {
    name: 'Doomer',
    year: 2018,
    user: '',
    platform: '4 chan',
    link: 'https://knowyourmeme.com/memes/doomer',
  },
  '014_fat-wojak': {
    name: 'Fat Wojak',
    year: 'https://imgur.com/r/Wojak/py9NcxL',
    user: '',
    platform: 'r/wojak',
    link: '',
  },
  '015_wojak-stage-5.5-doomer': {
    name: 'Stage 5.5 Doomer',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/doomer',
  },
  '016_the-22-yar-old-doomer': {
    name: 'The 22 year old doomer',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/doomer',
  },
  '017_the-surviving-doomer': {
    name: 'The surviving doomer',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/doomer',
  },
  '018_the-30-year-old-doomette': {
    name: 'The 30 year old doomette',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/doomer-girl',
  },
  '019_this-is-a-2009-rage-comic': {
    name: 'This is a 2009 rage comic',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '020_express-yourself-with-wojaks': {
    name: 'Express yourself with Wojaks',
    year: '',
    user: '',
    platform: '',
    link: 'https://www.nssmag.com/en/fashion/21372/how-wojak-became-the-meme-of-the-moment',
  },
  '021_pretty-princess-points': {
    name: 'Pretty Princess Points',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/tendies-stories',
  },
  '022_pretty-princess-points': {
    name: 'Pretty Princess Points',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/tendies-stories',
  },
  '023_pretty-princess-points': {
    name: 'Pretty Princess Points',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/tendies-stories',
  },
  '024_pretty-princess-points': {
    name: 'Pretty Princess Points',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/tendies-stories',
  },
  '025_pretty-princess-points-u_bigbigbadboy': {
    name: 'Pretty Princess Points',
    year: 'u_bigbigbadboy',
    user: 'bigbigbadboy',
    platform: 'Reddit r/wojak',
    link: '',
  },
  '026_grayons': {
    name: 'Grayons',
    year: '',
    user: '',
    platform: '',
    link: 'https://knowyourmeme.com/memes/brainlet',
  },
  '027_grayons-female-brainlet': {
    name: 'Grayons Female Brainlet',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '028_brainlet-small-engine-brain': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '029_windmill-brainlet': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '030_brainlet-thomas-tank-engine': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '031_brainlet-cube-drool': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '032_brainlet-microwaved-brain': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '033_brainlet-via-politigra.circlejerk.v5': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '034_suction-point': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '035_branlet-blob-with-construction-scraps': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '036_grug-chubby': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '037_grug-smiling': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '038_grug-riding-warthog': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '039_grug-playing-prehistoric-nintendo-switch': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: 'https://www.memeatlas.com/grug-memes.html',
  },
  '040_boomer-grug-wakes-up-zoomer-grug': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '041_boomer-monster': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '042_boomer-room': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '043_boomers-martin-luther': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '044_boomer-money-printer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '045_boomer-old-lakehouse': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '046_zoomer-eating-tide-pods': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: 'https://www.memeatlas.com/zoomer-memes.html',
  },
  '047_zoomer-tide-pods': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '048_a-20-year-old-zoomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '049_the-1997-og-zoomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '050_zoomer-chart': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '051_the-32-year-old-groomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '052_the-24-yo-improover.': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '053_the-29-year-old-goomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '054_the-28-year-old-mooncher': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '055_the-30-year-old-anti-coomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '056_the-26-year-old-goomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '057_the-almost-30-year.old-animal': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '058_the-coomer': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '059_the-driver': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '060_via-0xsalon-ig': {
    name: '',
    year: '',
    user: 'via-0xsalon-ig',
    platform: '',
    link: '',
  },
  '061_TradGirl': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '062_pink-hair-girl-wojak': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '063_via-m3m3pl3x': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '064_doomer-girl': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '065_soy-girl': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '066_doomer_wow-he-is-literally-me': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '067_emo-girl-wow-she-is-literally-me': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '068_doomer-red-girl-zoomer--wow-she-is-literally-me': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '069_dark-guy-wow-she-is-literally-me': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '070_yes-chad-wow-she-is-literally-me': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '071_YesChadDress': {
    name: 'Soyjak Fans vs. Chad Fans',
    year: 2020,
    user: 'https://www.reddit.com/user/Nichijoke/',
    platform: 'Reddit',
    link: 'Know Your Meme',
  },
  '072_StriveChadVSVirginWalk': {
    name: 'Virgin Walk VS Chad Strive',
    year: 2017,
    user: 'https://www.reddit.com/user/PyrusSolus/',
    platform: '4chan',
    link: '4chan archive',
  },
  '073_StriveChadVSVirginWalkExchange': {
    name: 'Virgin Walk VS Chad Strive',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '074_StriveChadVSVirginWalkUpdate': {
    name: 'Virgin Walk VS Chad Strive',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '075_StriveChadVSVirginWalkSwap': {
    name: 'Virgin Walk VS Chad Strive',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '076_ComunistChadVSLiberalVirgin': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '077_CommunistBotChadVSWesternGamerVirgin': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '078_RoyalBritVirginVSPresidentTrumpChad': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '079_InsurrectorChadVSConspiracyVirginProtestor': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '080_NaziVirginVSRobotChad': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '081_SanAndreasChadVSGTAVirgin': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '082_MinecraftGuyVSSteve': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '083_CrystalHeartChadVSVirginLifeFruit': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '084_SonyChadVSVirginNintendo': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '085_VirginPlayinCharacterVSChadNPC': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '086_DoggoVSCheemGamers': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '087_DoggoVSCheemCelphones': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '088_DoggoVSCheemArtists': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '089_DoggoVSCheemVSGrandpa': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '090_DoggoVSCheem': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '091_BeckyBeingVSStaceyGoddess': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '092_CatwomanBeckyVSPoisonIvyStacey': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '093_BeckySnowhiteVSQueenStacey': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '094_BusinessBeckyVSWorkLifeBalanceStacey': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '095_StaceyBeckyVSBeckyStacey': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '096_YesChadLGBTQ': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '097_VirginChadWholesomeVirtuos': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '098_VirginVSChadWholesomeInverted': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '099_MistakesIntoMiracles': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '100_ChadVirginFriendship': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '101_ActualChatMeme': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '102_TextwallChadVSMultitextVirgin': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '103_WhiteModeRedditChadVSVirginUnite': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '104_MetaChadVSVirgin': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '105_YesChadWomen': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '106_BeckyStacyManga': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '107_BeckyStacyStorytelling': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '108_BeckyStacyStorytelling_Inversed': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '109_BeckyStacyStorytelling_Inversed_Reversed': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '110_VirginChadMangaLGBT': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '111_just-get-a-girlfriend-already': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '112_wow-poliamorous': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '113_by_shukeiart': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '114_by-taraadhyaiatmaputri': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '115_hot-girls': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  // Level 6
  affordablefoiegras: {
    name: '',
    year: '',
    user: 'affordablefoiegras',
    platform: '',
    link: '',
  },
  babayagabiscuits: {
    name: '',
    year: '',
    user: 'babayagabiscuits',
    platform: '',
    link: '',
  },
  babayagabiscuits_2: {
    name: '',
    year: '',
    user: 'babayagabiscuits',
    platform: '',
    link: '',
  },
  babayagabiscuits_3: {
    name: '',
    year: '',
    user: 'babayagabiscuits',
    platform: '',
    link: '',
  },
  blackpowerbottomtext: {
    name: '',
    year: '',
    user: 'blackpowerbottomtext',
    platform: '',
    link: '',
  },
  'blu3.rar3': {
    name: '',
    year: '',
    user: 'blu',
    platform: '',
    link: '',
  },
  bpdepresso: {
    name: '',
    year: '',
    user: 'bpdepresso',
    platform: '',
    link: '',
  },
  chrisshattermemes: {
    name: '',
    year: '',
    user: 'chrisshattermemes',
    platform: '',
    link: '',
  },
  dayyyofff: {
    name: '',
    year: '',
    user: 'dayyyofff',
    platform: '',
    link: '',
  },
  'decolonial.meme.queens': {
    name: '',
    year: '',
    user: 'decolonial.meme.queens',
    platform: '',
    link: '',
  },
  eel_merchant: {
    name: '',
    year: '',
    user: 'eel_merchant',
    platform: '',
    link: '',
  },
  eel_merchant_2: {
    name: '',
    year: '',
    user: 'eel_merchant',
    platform: '',
    link: '',
  },
  eel_merchant3: {
    name: '',
    year: '',
    user: 'eel_merchant',
    platform: '',
    link: '',
  },
  fronty_python: {
    name: '',
    year: '',
    user: 'francesca.pharris',
    platform: '',
    link: '',
  },
  'fronty.python': {
    name: '',
    year: '',
    user: 'fronty.python',
    platform: '',
    link: '',
  },
  'fronty.python_2': {
    name: '',
    year: '',
    user: 'fronty.python',
    platform: '',
    link: '',
  },
  'francesca.pharris': {
    name: '',
    year: '',
    user: 'fronty.python',
    platform: '',
    link: '',
  },
  hangoverpiss: {
    name: '',
    year: '',
    user: 'hangoverpiss',
    platform: '',
    link: '',
  },
  harrisonlambert_memes: {
    name: '',
    year: '',
    user: 'harrisonlambert_memes',
    platform: '',
    link: '',
  },
  IMG_1729: {
    name: '',
    year: '',
    user: 'unknown',
    platform: '',
    link: '',
  },
  'kookyfonts-3': {
    name: '',
    year: '',
    user: 'kookyfonts',
    platform: '',
    link: '',
  },
  'kookyfonts-10': {
    name: '',
    year: '',
    user: 'kookyfonts',
    platform: '',
    link: '',
  },
  'kookyfonts-11': {
    name: '',
    year: '',
    user: 'kookyfonts',
    platform: '',
    link: '',
  },
  'males_are_cancelled-1': {
    name: '',
    year: '',
    user: 'males_are_cancelled',
    platform: '',
    link: '',
  },
  'males_are_cancelled-2': {
    name: '',
    year: '',
    user: 'males_are_cancelled',
    platform: '',
    link: '',
  },
  'males_are_cancelled-4': {
    name: '',
    year: '',
    user: 'males_are_cancelled',
    platform: '',
    link: '',
  },
  males_are_cancelled: {
    name: '',
    year: '',
    user: 'males_are_cancelled6',
    platform: '',
    link: '',
  },
  males_are_cancelled6: {
    name: '',
    year: '',
    user: 'numetalkaratesociety',
    platform: '',
    link: '',
  },
  numetalkaratesociety: {
    name: '',
    year: '',
    user: 'paris2000s',
    platform: '',
    link: '',
  },
  paris2000s: {
    name: '',
    year: '',
    user: 'reinassance_man',
    platform: '',
    link: '',
  },
  reinassance_man: {
    name: '',
    year: '',
    user: 'reinassance_man',
    platform: '',
    link: '',
  },
  reinassance_man2: {
    name: '',
    year: '',
    user: 'reinassance_man',
    platform: '',
    link: '',
  },
  'relatable skinner': {
    name: '',
    year: '',
    user: 'relatable skinner',
    platform: '',
    link: '',
  },
  'renaissance__man-1': {
    name: '',
    year: '',
    user: 'renaissance__man',
    platform: '',
    link: '',
  },
  'renaissance__man-2': {
    name: '',
    year: '',
    user: 'renaissance__man',
    platform: '',
    link: '',
  },
  'renaissance__man-3': {
    name: '',
    year: '',
    user: 'renaissance__man',
    platform: '',
    link: '',
  },
  'renaissance__man-6': {
    name: '',
    year: '',
    user: 'renaissance__man',
    platform: '',
    link: '',
  },
  spilledmyjuice: {
    name: '',
    year: '',
    user: 'spilledmyjuice',
    platform: '',
    link: '',
  },
  tatumstrangely: {
    name: '',
    year: '',
    user: 'tatumstrangely',
    platform: '',
    link: '',
  },
  teenagestepdad_2: {
    name: '',
    year: '',
    user: 'teenagestepdad',
    platform: '',
    link: '',
  },
  teenagestepdad_3: {
    name: '',
    year: '',
    user: 'teenagestepdad',
    platform: '',
    link: '',
  },
  'teenagestepdad-2': {
    name: '',
    year: '',
    user: 'teenagestepdad',
    platform: '',
    link: '',
  },
  'teenagestepdad-3': {
    name: '',
    year: '',
    user: 'teenagestepdad',
    platform: '',
    link: '',
  },
  teenagestepdad: {
    name: '',
    year: '',
    user: 'teenagestepdad',
    platform: '',
    link: '',
  },
  unboundbabes: {
    name: '',
    year: '',
    user: 'unboundbabes',
    platform: '',
    link: '',
  },
  unknown: {
    name: '',
    year: '',
    user: 'unknown',
    platform: '',
    link: '',
  },

  walch_winkler: {
    name: '',
    year: '',
    user: 'walch_winkler',
    platform: '',
    link: '',
  },
  'what the fuck (maybe kookyfonts)': {
    name: '',
    year: '',
    user: 'what the fuck (maybe kookyfonts)',
    platform: '',
    link: '',
  },
  'wurfelhouse-6': {
    name: '',
    year: '',
    user: 'wurfelhouse',
    platform: '',
    link: '',
  },
  'wurfelhouse-7': {
    name: '',
    year: '',
    user: 'wurfelhouse',
    platform: '',
    link: '',
  },
  // Level 7
  'alone on a friday night confucius sonic_Masha Zharova': {
    name: 'Alone on a friday night confucius sonic',
    year: '',
    user: "The Philosopher's Meme - Masha Zharova",
    platform: '',
    link: '',
  },
  isthisloss30: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'space feels_Masha Zharova': {
    name: 'Space Feels',
    year: '',
    user: "The Philosopher's Meme - Masha Zharova",
    platform: '',
    link: '',
  },
  isthisloss13: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Recognition of Pattern': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Introduction to a Golden Ratio': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Seong-Young Her2': {
    name: '',
    year: '',
    user: "The Philosopher's Meme - Seong-Young Her",
    platform: '',
    link: '',
  },
  'Seong-Young Her': {
    name: '',
    year: '',
    user: "The Philosopher's Meme - Seong-Young Her",
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_55_28': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_7@18-07-2020_10-29-05': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Screenshot 2022-05-13 at 16.57.15': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss23: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss5: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss15: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss10: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2020-11-18 20_57_16': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Seong-Young Her3': {
    name: '',
    year: '',
    user: "The Philosopher's Meme - Seong-Young Her",
    platform: '',
    link: '',
  },
  isthisloss28: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss18: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss20: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  IMG_4105: {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss25: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  isthisloss8: {
    name: 'Is This Loss?',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_5@16-07-2020_22-07-51': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  dogggggo: {
    name: 'dogggggo',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_54_32': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Seong-Young Her and Masha Zharova': {
    name: '',
    year: '',
    user: "The Philosopher's Meme - Seong-Young Her and Masha Zharova ",
    platform: '',
    link: '',
  },
  'The Philosopher_s Meme': {
    name: '',
    year: '',
    user: "The Philosopher's Meme",
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13_23_04': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2020-11-18 20_57_08': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_58_08': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '123990176_2849569502034737_4142775946265224422_o': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'logos doggos': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '62f128f0f4cf9d1af2574d1cca72fc72 (1)': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '108056805_983504702081227_2245310705315660653_n': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_55_04': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2@13-07-2020_19-09-43': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '60342239_2501257949884963_5959021433890799616_n': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_55_12': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2@11-07-2020_20-04-00': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2022-04-27 17.05.59.': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13_21_52': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '2018-02-21 14.09.38': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_55_19': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '29510816_188880438506723_3933783927320140106_n': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_54_49': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_54_38': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2022-05-13 16_57_23': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'if-i-am-walking_confucius_Masha Zharova': {
    name: 'If I am walking Confucius',
    year: '',
    user: "The Philosopher's Meme - Masha Zharova",
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13_21_57': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'peace and contentment - confucius_Masha Zharova': {
    name: 'Peace and contentment Confucius',
    year: '',
    user: "The Philosopher's Meme - Masha Zharova",
    platform: '',
    link: '',
  },
  // Level 8
  '48429688_551860351955599_5102919076091527168_o.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '53369489_1690347517777711_961730481374625792_n.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '60324826_914415468968782_642787202111111168_n.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '109940213_2650687378539337_8736195614228418357_n.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'a0a7a5a6cca24037e855a8096d7c3b83-1.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'I9rsTV82G9-gamT7PM6rdIu5r7JNYs6GkSmrQXQohcY.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/14/20.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/14/27.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/14/48.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/14/52.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/14/58.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/03.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/07.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/10.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/16.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/38.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/15/44.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/21/34.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_7@13-07-2020_19-11-23.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_9@20-02-2020_18-19-51.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_14@18-07-2020_10-28-43.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_16@18-07-2020_10-28-43.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_19@18-07-2020_10-38-03.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_20@18-07-2020_12-36-03.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_23@29-06-2020_10-26-12.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2020-07-20_10-59-03.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2021-05-20 13.06.36.jpeg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2021-05-20 13.06.43.jpeg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_2021-05-20 13.14.01.jpeg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'photo_14296@13-07-2020_21-05-51.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Screenshot 2020-07-18 at 12.32.36.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 81.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '0ffc22d1f6942d342906590e9ad2fd5d.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '5ef8058b3cefbbd46e1507152f042f2a.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '979cc3f70e3e0c83db2a651a3e4dc053.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '82611279_498249997755797_368519101373085991_n.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'bf538423f1d6a2774b26c4fd18613d70.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'cfd68a782becfa38a47b11da44d597d7.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 77.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 78.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 79.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 80.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 82.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 83.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 84.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 87.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 90.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 91.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 93.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 94.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/17/05.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/17/12.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/17/18.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/18/03.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/18/07.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/18/17.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'IMAGE 2021-05-20 13/18/50.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Screenshot 2021-05-20 at 13.16.43.png': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  'Image 11.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_001.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_002.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_003.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_004.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_005.png': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_006.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_007.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_008.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_009.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_010.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_011.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_012.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_013.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_014.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_015.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_016.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_017.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_019.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_020.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_021.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_022.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_023.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_024.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_025.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_026.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_028.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_029.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_030.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_031.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_032.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_033.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_034.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_035.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_036.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_037.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_038.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_039.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_040.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_041.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_042.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_043.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_044.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_045.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
  '10_046.jpg': {
    name: '',
    year: '',
    user: '',
    platform: '',
    link: '',
  },
}

export default memes
