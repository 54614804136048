import styled from 'styled-components'
import { fontSneakyTitling } from '../theme/theme'

const TextMeme = styled.div`
  ${fontSneakyTitling}
  overflow: hidden;
  font-size: clamp(56px, 27vw, 225px);
  margin-bottom: -0.5ex;
`

const TextManif = styled.div`
  font-size: clamp(56px, 16vw, 128px);
`

export const TitleMemeManifesto: React.FC = () => {
  return (
    <>
      <TextMeme>MEME</TextMeme>
      <TextManif>MANIFESTO</TextManif>
    </>
  )
}
