import { memo, useContext, useEffect, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { GatsbyImage } from 'gatsby-plugin-image'

import { IcebergMeme as IcebergMemeType } from '../../@types/mememanifesto'
import { MemeZoomContext } from '../../contexts/MemeZoomContext'
import randomInt from '../../utils/randomInt'
import { IcebergContext } from '../../contexts/IcebergContext'
import { AppContext } from '../../contexts/AppContext'

export const IcebergMeme = memo<{
  image: IcebergMemeType
  width: number
  verticalMargin: number
}>(
  ({ image, width, verticalMargin }) => {
    const { setMeme } = useContext(MemeZoomContext)
    const { menuOpen } = useContext(IcebergContext)
    const { isTablet } = useContext(AppContext)
    const [speed] = useState(() => randomInt(0, 100))
    const [offsetX, setOffsetX] = useState<number>()

    useEffect(() => {
      setOffsetX(randomInt(width, 100))
    }, [width])

    if (offsetX === undefined) return null

    const inner = (
      <div
        style={{
          cursor: 'pointer',
          pointerEvents: 'all',
          width: `max(120px, ${width}vw)`,
          opacity: width / 100 + 0.6,
          // Applies a random X offset while ensuring that the image always fits in the screen
          transform: `translateX(calc(max(100px, ${offsetX}vw) - 100%))`,
        }}
        onClick={() => {
          if (!(isTablet === true && menuOpen)) {
            setMeme(image)
          }
        }}
        role="button"
        tabIndex={0}
      >
        {image && image.image && <GatsbyImage image={image.image} alt="" />}
        {image?.contrib === true && image.publicURL && (
          <div>
            <img src={image.publicURL} alt="Contrib Meme" />
          </div>
        )}
      </div>
    )

    return (
      <div
        style={{
          marginTop: verticalMargin,
          mixBlendMode: 'difference',
          zIndex: 1,
          position: 'relative',
          pointerEvents: 'none',
        }}
      >
        <Parallax speed={speed}>{inner}</Parallax>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.image.publicURL === nextProps.image.publicURL &&
      prevProps.verticalMargin === nextProps.verticalMargin &&
      prevProps.width === nextProps.width
    )
  }
)
