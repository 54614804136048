/**
 * Divides an array in subarrays of equal length
 *
 * @param arr
 * @param size
 */
const chunk = <T>(arr: T[], size: number) => {
  let chunked = []
  for (let i = 0, j = arr.length; i < j; i += size) {
    chunked.push(arr.slice(i, i + size))
  }
  return chunked
}

export default chunk
