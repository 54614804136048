import styled from 'styled-components'

import { IcebergLevelsWrapper } from './IcebergLevelsWrapper'

const Ice = styled.div`
  color: ${({ theme }) => theme.color('bottomTextColor')};
  position: relative;
`

const SurfaceImage = styled.div`
  height: 40vw;
  width: 100%;
  pointer-events: none;
  z-index: 3;
  position: absolute;
  margin-top: -20vw;
`

const SurfaceImageTop = styled(SurfaceImage)`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`

const SurfaceImageBottom = styled(SurfaceImage)`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
`

export const Iceberg: React.FC = () => {
  return (
    <Ice>
      <SurfaceImageTop />
      <IcebergLevelsWrapper />
      <SurfaceImageBottom />
    </Ice>
  )
}
