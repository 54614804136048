import styled from 'styled-components'

import { Flex } from './Flex'
import { SlideSide } from '../@types/mememanifesto'
import { forwardRef } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  side: SlideSide
  heading?: React.ReactNode
  subtitles?: React.ReactNode
}

export const SlidePadding = '5vw'

const FlexS = styled(Flex)<{
  side: Props['side']
}>`
  position: relative;
  padding: calc(
      12.5vh + ${({ side, theme }) => (side === 'top' ? theme.menuHeight : 0)}px
    )
    ${SlidePadding} 16px;
  height: var(--vh);
  color: ${({ side, theme }) =>
    theme.color(side === 'bottom' ? 'bottomTextColor' : 'topTextColor')};
  background-color: ${({ side, theme }) =>
    theme.color(
      side === 'bottom' ? 'bottomBackgroundColor' : 'backgroundColor'
    )};
  overflow-y: auto;
  transform: translateY(
    -${({ side, theme }) => (side === 'top' ? theme.menuHeight : 0)}px
  );
`

const Header = styled.header`
  position: relative;
  text-align: center;
`

const Heading = styled.h1`
  margin-bottom: 0;
  font-weight: normal;
`

const Content = styled.div`
  flex: 1;

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

const Subtitles = styled.div`
  margin-top: 5vh;
`

export const Slide = forwardRef<HTMLDivElement, Props>(
  ({ side, heading, subtitles, children, ...props }, ref) => {
    return (
      <FlexS side={side} column fillParent>
        <Header>
          {heading && <Heading>{heading}</Heading>}
          {subtitles && <Subtitles>{subtitles}</Subtitles>}
        </Header>
        <Content {...props}>{children}</Content>
      </FlexS>
    )
  }
)
