import { useEffect } from 'react'

/** Always call with useCallback: useOnScroll(useCallback(callback, [])) */
export const useOnScroll = (
  onScroll: EventListenerOrEventListenerObject,
  el: GlobalEventHandlers = window
) => {
  useEffect(() => {
    el.addEventListener('scroll', onScroll)
    return () => el.removeEventListener('scroll', onScroll)
  }, [onScroll, el])
}
