import { useContext } from 'react'
import styled from 'styled-components'

import { Flex } from './Flex'
import { MemeZoomContext } from '../contexts/MemeZoomContext'
import { MemeInfo } from './MemeInfo'
import MemeList from '../levels/memes'

const Wrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  background: linear-gradient(
    to bottom,
    #000 calc(var(--vh) * 0.2),
    rgba(0, 0, 0, 0.9) calc(var(--vh) * 0.6),
    rgba(0, 0, 0, 0) var(--vh)
  );
  z-index: 3;
  cursor: pointer;
`

const InfoWrapper = styled.div``

const MemeWrapper = styled(Flex)`
  flex: 1;
  position: relative;
`

const Meme = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const MemeZoom: React.FC = () => {
  const { meme, setMeme } = useContext(MemeZoomContext)

  if (meme === null) return null
  const info = MemeList[meme.name] ?? {}

  return (
    <Wrapper column onClick={() => setMeme(null)}>
      <InfoWrapper>
        <MemeInfo
          name={info.name}
          year={info.year}
          user={info.user}
          platform={info.platform}
          description={info.description}
          link={info.link}
        />
      </InfoWrapper>
      <MemeWrapper>
        <Meme
          alt={meme.name}
          src={meme.publicURL}
          width={meme.image ? meme.image.width : undefined}
          height={meme.image ? meme.image.height : undefined}
        />
      </MemeWrapper>
    </Wrapper>
  )
}
