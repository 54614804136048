import styled from 'styled-components'
import { fontSneaky, fontSneakyTitling } from '../theme/theme'

const PreTitle = styled.div`
  ${fontSneaky}
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 0.5em;
`

const The = styled.div`
  ${fontSneakyTitling}
  overflow: hidden;
`

const Ice = styled.div`
  font-size: clamp(80px, 22.23vw, 128px);
  margin-top: -0.65ex;
`

export const TitleTheIceberg: React.FC = () => {
  return (
    <>
      <PreTitle>
        A<br />
        Meme
        <br />
        Manifesto
        <br />
        Project
        <br />
      </PreTitle>
      <The>THE</The>
      <Ice>ICEBERG</Ice>
    </>
  )
}
