import styled from 'styled-components'

import { Meme } from '../@types/mememanifesto'
import { fontImpakt, fontSneaky } from '../theme/theme'

const Wrapper = styled.div`
  line-height: 1.5;

  p {
    margin: 0;
    font-size: 1rem;
  }
`

const Label = styled.span`
  ${fontImpakt}
`
const Value = styled.span`
  ${fontSneaky}
`

export const MemeInfo: React.FC<Meme> = ({
  name,
  year,
  user,
  platform,
  description,
  link,
}) => {
  return (
    <Wrapper>
      {name && (
        <p>
          <Label>NAME:</Label> <Value>{name}</Value>
        </p>
      )}
      {year && (
        <p>
          <Label>YEAR:</Label> <Value>{year}</Value>
        </p>
      )}
      {user && (
        <p>
          <Label>USER:</Label> <Value>{user}</Value>
        </p>
      )}
      {platform && (
        <p>
          <Label>PLATFORM:</Label> <Value>{platform}</Value>
        </p>
      )}
      {description && (
        <p>
          <Label>DESCRIPTION:</Label> <Value>{description}</Value>
        </p>
      )}
      {link && (
        <p>
          <Value>
            <em>
              <a href={link} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </em>
          </Value>
        </p>
      )}
    </Wrapper>
  )
}
