import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-cool-inview'

import { IcebergMeme } from './IcebergMeme'
import { SlidePadding } from '../Slide'

import chunk from '../../utils/chunk'
import randomInt from '../../utils/randomInt'
import {
  IcebergLevels,
  IcebergMeme as IcebergMemeType,
} from '../../@types/mememanifesto'
import { fontSneaky } from '../../theme/theme'
import { IcebergLevelFish } from './IcebergLevelFish'
import { IcebergContext } from '../../contexts/IcebergContext'

const gap = 5

const Headings = styled.div`
  min-height: calc(var(--vh) * 2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 ${SlidePadding};
  text-align: center;
  text-shadow: 0 0 5px #fff;

  ${props => props.theme.mediaQuery('tablet')} {
    max-width: 55vw;
  }
`

const Heading = styled.h2`
  text-transform: uppercase;
  margin-bottom: 0.25em;
  line-height: 1;
  font-size: 14vw;

  ${props => props.theme.mediaQuery('tablet')} {
    font-size: 8vw;
  }

  ${props => props.theme.mediaQuery('desktop')} {
    font-size: 9vw;
  }
`

const SubHeading = styled.h3`
  ${fontSneaky}
  margin-bottom: ${gap}rem;
  font-size: clamp(24px, 2.5vw, 32px);
`

interface IcebergLevelProps {
  level: string
}

const StyledIcebergLevel = styled.div.attrs<IcebergLevelProps>(props => ({
  id: `iceberg-${props.level}`,
}))<IcebergLevelProps>`
  margin-top: 25vw;
  padding-top: 5vw;
  position: relative;
  z-index: 1;
  min-height: 10000px;
  overflow: hidden;
`

interface Props {
  level: IcebergLevels
  images: IcebergMemeType[]
  title: string
  heading: React.ReactNode
  subheading: React.ReactNode
  middleText: React.ReactNode
  imageWidth: number | [number, number]
  verticalMargin: number | [number, number]
  visible: boolean
  setInViewLevel: (level: IcebergLevels) => void
}

export const IcebergLevel: React.FC<Props> = ({
  level,
  images,
  title,
  heading,
  subheading,
  middleText,
  imageWidth,
  verticalMargin,
  visible,
  setInViewLevel,
}) => {
  const { sound, scrollToLevel, setScrollToLevel } = useContext(IcebergContext)
  const [widths, setWidths] = useState<{ [key: string]: number }>()
  const [gridTop, setGridTop] = useState<React.ReactNode[]>([])
  const [gridBottom, setGridBottom] = useState<React.ReactNode[]>([])

  const imageWidthMin = Array.isArray(imageWidth) ? imageWidth[0] : imageWidth
  const imageWidthMax = Array.isArray(imageWidth) ? imageWidth[1] : imageWidth

  const verticalMarginMin = Array.isArray(verticalMargin)
    ? verticalMargin[0]
    : verticalMargin
  const verticalMarginMax = Array.isArray(verticalMargin)
    ? verticalMargin[1]
    : verticalMargin

  const anchorRef = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    if (scrollToLevel === level && anchorRef.current) {
      anchorRef.current.scrollIntoView({ block: 'center' })
      setScrollToLevel(undefined)
    }
  }, [scrollToLevel, level, setScrollToLevel])

  useEffect(() => {
    if (!images.length) return

    setWidths(oldWidths => {
      const newWidths = { ...oldWidths }
      images.forEach(image => {
        if (!newWidths[image.name]) {
          newWidths[image.name] = randomInt(imageWidthMin, imageWidthMax)
        }
      })
      return newWidths
    })
  }, [images, imageWidthMin, imageWidthMax])

  useEffect(() => {
    if (!widths || !visible) return
    const grid = images.map(image => (
      <IcebergMeme
        key={image.name}
        image={image}
        width={widths[image.name]}
        verticalMargin={randomInt(verticalMarginMin, verticalMarginMax)}
      />
    ))
    const [gridTop, gridBottom] = chunk(grid, Math.ceil(grid.length / 2))
    setGridTop(gridTop)
    setGridBottom(gridBottom)
  }, [images, visible, widths, verticalMarginMin, verticalMarginMax])

  const { observe } = useInView({
    onEnter: () => {
      setInViewLevel(level)
      sound.setSoundLevel(level)
    },
  })

  return (
    <StyledIcebergLevel id={`level-${level}`} level={level} ref={observe}>
      <Headings>
        <Heading id={`iceberg-${level}-anchor`} ref={anchorRef}>
          {heading}
        </Heading>
        <SubHeading>{subheading}</SubHeading>
      </Headings>
      {gridTop}
      <IcebergLevelFish level={level} title={title} middleText={middleText} />
      {gridBottom}
    </StyledIcebergLevel>
  )
}
