import { useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { IcebergContext } from '../../contexts/IcebergContext'
import { MenuMobileLink, StyledMenuLink } from './MenuMobileLink'
import { MenuSoundButton } from './MenuSoundButton'
import { Archive } from '../icons/Archive'
import { ArchiveFilled } from '../icons/ArchiveFilled'
import { Iceberg } from '../icons/Iceberg'
import { IcebergFilled } from '../icons/IcebergFilled'
import { Manifesto } from '../icons/Manifesto'
import { ManifestoFilled } from '../icons/ManifestoFilled'
import { Detective } from '../icons/Detective'
import { Button } from '../buttons/Button'
import { PillShadow } from '../buttons/PillShadow'
import { SlideName, SlideSide } from '../../@types/mememanifesto'

const ButtonSound = styled(Button)``

const Nav = styled.nav<{ side: SlideSide | 'floating' }>`
  height: ${({ theme }) => theme.menuHeight}px;
  color: ${({ theme, side }) =>
    theme.color(side === 'top' ? 'topTextColor' : 'bottomTextColor')};
  position: relative;
  z-index: 1;

  ${({ side }) => {
    if (side === 'bottom' || side === 'floating') {
      return `
        position: absolute;
        bottom: 0;
        width: 100vw;`
    }
  }}

  ${({ side }) => {
    if (side === 'bottom') {
      return 'background-image: linear-gradient(to top, #000000d6, #000000a3 67%, #0000);'
    }
  }}

  ${ButtonSound} {
    ${({ theme }) =>
      `
        fill: ${theme.color('topTextColor')};
        .stroke {
          stroke: ${theme.color('topTextColor')}; !important;
        }`}
  }
`

const PillShadowS = styled(PillShadow)`
  width: 80vw;
  min-width: 300px;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: space-around;
  height: 47px;
  align-items: center;

  line-height: 1;

  > * {
    margin: 0 1vw;
  }
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 32px;
`

interface Props {
  side: SlideSide | 'floating'
}

export const MenuMobile: React.FC<Props> = ({ side }) => {
  const { topSlide, bottomSlide } = useContext(IcebergContext)
  const active = (slide: SlideName) =>
    (side === 'top' && topSlide === slide) ||
    (side === 'bottom' && bottomSlide === slide) ||
    (side === 'floating' && 'iceberg' === slide)

  return (
    <Nav side={side}>
      <Wrapper>
        <PillShadowS>
          <MenuMobileLink slide="about" side={side}>
            {active('about') && <ManifestoFilled />}
            {!active('about') && <Manifesto />}
          </MenuMobileLink>
          <MenuMobileLink slide="iceberg" side={side}>
            {active('iceberg') && <IcebergFilled />}
            {!active('iceberg') && <Iceberg />}
          </MenuMobileLink>
          <MenuMobileLink slide="archive" side={side}>
            {active('archive') && <ArchiveFilled />}
            {!active('archive') && <Archive />}
          </MenuMobileLink>
          <StyledMenuLink>
            <Link to="/detective-wall">
              <Detective />
            </Link>
          </StyledMenuLink>
          <StyledMenuLink>
            <MenuSoundButton />
          </StyledMenuLink>
        </PillShadowS>
      </Wrapper>
    </Nav>
  )
}
