import { createContext } from 'react'
import { IcebergMeme } from '../@types/mememanifesto'

export const MemeZoomContext = createContext<{
  meme: null | IcebergMeme
  setMeme: (meme: null | IcebergMeme) => void
}>({
  meme: null,
  setMeme: () => {},
})
